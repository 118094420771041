<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-container>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="comment.resolved"
            x-small
            icon
            style="position: absolute; top: 25px; left: 35px; z-index: 1;"
            v-bind="attrs"
            v-on="on"
            @click.stop="unresolveComment(comment)"
          >
            <v-icon
              color="primary"
            >
              {{ icon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('actions|unresolve_comment') }}</span>
      </v-tooltip>
      <v-tooltip
        v-if="comment.resolved && collabsOnResolved && comment.replies.arr && comment.replies.arr.length"
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            icon
            style="position: absolute; bottom: 25px; right: 35px; z-index: 1;"
            v-bind="attrs"
            v-on="on"
            @click.stop="showReplies()"
          >
            <v-icon
              color="primary"
            >
              mdi-menu-down
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('actions|show_replies') }}</span>
      </v-tooltip>
      <v-tooltip
        v-if="comment.resolved && !collabsOnResolved"
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            icon
            style="position: absolute; bottom: 25px; right: 35px; z-index: 1;"
            v-bind="attrs"
            v-on="on"
            @click.stop="hideReplies()"
          >
            <v-icon
              color="primary"
            >
              mdi-menu-up
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('actions|hide_replies') }}</span>
      </v-tooltip>
      <v-card
        v-click-outside="hide"
        class="my-2 pa-2"
        :elevation="hover || elevationEffect && commentId === comment.id ? 8 : 2"
        :disabled="comment.resolved"
        color="white"
        :ripple="false"
        @click.stop="openReplyField"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              color="primary"
              style="position: absolute; top: 5px; right: 35px;"
              v-bind="attrs"
              v-on="on"
              @click.stop="resolveComment(comment)"
            >
              mdi-check
            </v-icon>
          </template>
          <span>{{ $t('actions|resolve_comment') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              style="position: absolute; top: 5px; right: 10px;"
              v-bind="attrs"
              v-on="on"
              @click="deleteComment(comment)"
            >
              mdi-close
            </v-icon>
          </template>
          <span>{{ $t('actions|delete_entire_comment') }}</span>
        </v-tooltip>
        <v-card-text
          class="black--text"
        >
          <v-row class="mb-1 mt-1">
            <v-col
              cols="2"
            >
              <v-avatar
                size="26"
                color="primary"
              >
                <img
                  v-if="comment.avatar"
                  :src="comment.avatar"
                >
                <v-icon
                  v-else
                  size="20"
                  dark
                >
                  mdi-account-circle
                </v-icon>
              </v-avatar>
            </v-col>
            <v-col
              cols="10"
            >
              <span
                v-if="comment.createdBy"
                style="font-size: 15px"
                class="font-weight-medium ml-3"
              >
                {{ comment.createdBy }}
              </span>
            </v-col>
          </v-row>
          <span>{{ comment.commentText }}</span>
          <v-icon
            v-if="checkIfPermitted(comment.createdBy)"
            x-small
            color="primary"
            class="ml-3"
            @click="updateCommentsText(comment)"
          >
            mdi-pencil
          </v-icon>
          <v-divider
            v-if="comment.replies.arr && comment.replies.arr.length"
            class="my-3"
          />
          <div
            v-for="rep in comment.replies.arr"
            :key="rep.id"
            class="mt-3"
          >
            <div v-if="!collabsOnResolved">
              <v-row
                class="mb-1"
              >
                <v-col
                  cols="2"
                >
                  <v-avatar
                    size="26"
                    color="primary"
                  >
                    <img
                      v-if="rep.avatar"
                      :src="rep.avatar"
                    >
                    <v-icon
                      v-else
                      size="20"
                      dark
                    >
                      mdi-account-circle
                    </v-icon>
                  </v-avatar>
                </v-col>
                <v-col
                  cols="10"
                >
                  <span
                    style="font-size: 15px"
                    class="font-weight-medium ml-3"
                  >
                    {{ rep.author }}
                  </span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="checkIfPermitted(rep.author)"
                        small
                        style="position: absolute; right: 10px;"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteReply(rep, comment)"
                      >
                        mdi-delete-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('actions|delete_reply') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <span
                v-if="rep.replyText.length >= 88 && !showMore"
              >
                {{ rep.replyText.slice(0, 88) }}
              </span>
              <span v-else>
                {{ rep.replyText }}
              </span>
              <v-icon
                v-if="checkIfPermitted(rep.author)"
                x-small
                color="primary"
                class="ml-3"
                @click="updateReplyText(comment, rep)"
              >
                mdi-pencil
              </v-icon>
              <v-btn
                v-if="rep.replyText.length>=88 && !showMore"
                x-small
                text
                color="primary"
                @click.stop="showMore = true"
              >
                {{ $t('actions|show_more') }}
              </v-btn>
              <v-btn
                v-if="rep.replyText.length >= 88 && showMore"
                x-small
                text
                color="primary"
                @click.stop="showMore = false"
              >
                {{ $t('actions|show_less') }}
              </v-btn>
              <v-divider class="my-3" />
            </div>
          </div>
          <div
            v-if="openValue"
          >
            <v-text-field
              v-model="replyText"
              dense
              outlined
              placeholder="Reply"
              class="mt-4 mb-n6"
            />
            <span
              style="font-size: 12px"
            >
              {{ $t('expressions|viewers_can_see_comments_and_suggestions') }}
            </span>
          </div>
        </v-card-text>
        <v-card-actions v-if="openValue">
          <v-btn
            color="primary"
            x-small
            :disabled="!replyText"
            @click="reply(comment)"
          >
            {{ $t('actions|reply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-hover>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiCommentArrowLeftOutline } from '@mdi/js'
import { EventBus } from '@/utils/EventBus'

export default {
  props: {
    comment: {
      type: Object,
      default: null
    },
    template: {
      type: Object,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    },
    caseData: {
      type: Object,
      default: null
    },
    elevationEffect: {
      type: Boolean,
      default: false
    },
    commentId: {
      type: String,
      default: undefined
    }
  },
  data (instance) {
    return {
      replyText: '',
      openValue: false,
      showMore: false,
      icon: mdiCommentArrowLeftOutline,
      collabsOnResolved: instance._props.comment.resolved
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      actualCommentsEditorStorage: state => state.editorStore.actualCommentsEditorStorage
    }),
    actualComment () {
      return this.actualCommentsEditorStorage.find(comm => comm.attrs.id === this.comment.id)
    }
  },
  methods: {
    ...mapActions({
      deleteMyComment: 'cases/deleteMyComment',
      replyToComment: 'cases/updateCaseWorkbenchTemplate',
      updateMyComment: 'cases/updateCaseWorkbenchTemplate'
    }),
    openReplyField () {
      this.openValue = true
    },
    hide () {
      this.openValue = false
    },
    showReplies () {
      this.collabsOnResolved = false
    },
    hideReplies () {
      this.collabsOnResolved = true
    },
    getName (accountData) {
      let _name
      if (accountData.firstName && accountData.lastName) _name = accountData.firstName + ' ' + accountData.lastName
      else if (accountData.firstName && !accountData.lastName) _name = accountData.firstName
      else if (!accountData.firstName && accountData.lastName) _name = accountData.lastName
      else _name = accountData.email
      return _name
    },
    async resolveComment (comment) {
      this.loading = true
      EventBus.$emit('resolve-comment', comment)
      this.loading = false
    },
    async unresolveComment (comment) {
      this.loading = true
      EventBus.$emit('unresolve-comment', comment)
      this.loading = false
    },
    async updateCommentsText (comment) {
      this.loading = true
      const res = await this.$dialog.prompt({
        title: this.$t('actions|edit_comment'),
        text: this.$t('actions|edit_or_enter_new_text'),
        value: comment.text,
        showClose: false,
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          outlined: true,
          dense: true,
          type: 'text',
          class: 'mt-5'
        }
      })
      if (!res) return
      EventBus.$emit('comment-text-update', comment, res)
      this.loading = false
    },
    async deleteComment (comment) {
      EventBus.$emit('comment-deleted', comment)
    },
    async reply (comment) {
      const avatar = this.account.avatar ? `${process.env.VUE_APP_BACKEND_PATH}downloadAvatar/${this.account._id}/${this.account.avatar.raw.filename}` : ''
      EventBus.$emit('comment-reply', comment, this.replyText, this.account.accountData, avatar)
      this.replyText = ''
    },
    async updateReplyText (comment, rep) {
      this.loading = true
      const res = await this.$dialog.prompt({
        title: this.$t('actions|edit_reply'),
        text: this.$t('actions|edit_or_enter_new_text'),
        value: rep.replyText,
        showClose: false,
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          outlined: true,
          dense: true,
          type: 'text',
          class: 'mt-5'
        }
      })
      if (!res) return
      EventBus.$emit('comment-reply-text-update', comment, rep, res)
      this.loading = false
    },
    async deleteReply (reply, comment) {
      this.loading = true
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure_you_want_to_delete_this_resource')
      })
      if (res) {
        EventBus.$emit('delete-reply', reply, comment)
      }
    },
    checkIfPermitted (createdBy) {
      if (createdBy === this.account.accountData.firstName + ' ' + this.account.accountData.lastName) return true
      else if (createdBy === this.account.accountData.email) return true
      else return false
    }
  }
}
</script>
