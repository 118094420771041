<template>
  <v-dialog
    v-model="value"
    scrollable
    max-width="600px"
  >
    <v-card v-if="!loading">
      <v-card-title>
        <div>{{ $t('actions|edit_workbenches') }}</div>
        <v-spacer />
        <v-btn
          icon
          @click="$emit('input', !value)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div
          v-for="workbench in workbenches"
          :key="workbench._id"
          class="mx-2"
        >
          <v-text-field
            v-model="workbench.name"
            outlined
            dense
            :label="$t('mixed|workbench_name')"
            clearable
            class="mt-1"
            :placeholder="workbench.name"
          >
            <template slot="append-outer">
              <v-icon
                :disabled="workbenches.length < 2"
                color="primary"
                @click="$emit('delete', workbench)"
              >
                mdi-trash-can
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mt-n5">
        <v-btn
          color="primary"
          rounded
          small
          min-width="120"
          class="mb-2"
          @click="$emit('update', workbenches)"
        >
          {{ $t('actions|update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <div
      v-if="loading"
      style="width: 100%; height: 100%;"
    >
      <v-overlay
        opacity="0.68"
        :value="loading"
      >
        <v-progress-circular
          :size="50"
          color="white"
          indeterminate
        />
        <div style="position: relative; top: 15px; right: 40%;">
          <img
            src="@/assets/Lavirra_logo_SVG.svg"
            height="28px"
            width="auto"
          >
        </div>
      </v-overlay>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    workbenches: {
      type: Array,
      default: () => ([])
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
