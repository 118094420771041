<template>
  <!-- style="background-color: white;" -->
  <v-container
    :key="templateSuggestionsComponentKey"
    fluid
  >
    <div
      v-if="loading"
      style="width: 100%; height: 100%;"
    >
      <v-overlay
        absolute
        opacity="0.98"
        :value="loading"
      >
        <v-progress-circular
          :size="50"
          color="white"
          indeterminate
        />
        <div style="position: relative; top: 15px; right: 40%;">
          <img
            src="@/assets/Lavirra_logo_SVG.svg"
            height="28px"
            width="auto"
          >
        </div>
      </v-overlay>
    </div>
    <v-row dense>
      <v-col
        v-if="actualTemplate && account.accountType === 'lawyer'"
        cols="12"
        :sm="actualTabTemplateViewCases && actualTabTemplateViewCases === 2 ? '4' : '2'"
      >
        <v-card
          v-if="actualTabTemplateViewCases === 0"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
          class="pa-1"
        >
          <v-list
            :max-height="($vuetify.breakpoint.height - 81).toString()"
            style=" overflow-y: auto;"
            expand
          >
            <v-list-group
              v-if="actualTabTemplateViewCases === 0"
              :value="true"
              dense
              class="mt-n2"
            >
              <v-icon
                slot="prependIcon"
                size="20"
              >
                mdi-at
              </v-icon>
              <template
                v-slot:activator
              >
                <v-list-item-title
                  class="ml-n8"
                  style="font-size: 16px; font-weight: bold;"
                >
                  <span style="white-space: normal;">{{ $t('templates|template_suggestions') }}</span>
                </v-list-item-title>
              </template>
              <div
                v-if="actualSuggestionsEditorStorage.length"
                :key="templateSuggestionsComponentKey"
              >
                <v-list-item
                  v-for="(suggestion, index) in actualSuggestionsEditorStorage"
                  :key="index"
                  three-line
                  dense
                  @click="scrollToSuggestion(suggestion)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="primary--text"
                    >
                      @{{ suggestion.attrs.customLabel ? suggestion.attrs.label + '[' + suggestion.attrs.customLabel + ']' : suggestion.attrs.label }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                      class="mt-1"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ inUseInQuestionnaire(suggestion) ? 'mdi-checkbox-marked-circle-outline' : icons.notInUseInQuestionairre }}
                      </v-icon>
                      {{ inUseInQuestionnaire(suggestion) ? $t('mixed|in_use_in_questionnaire') : $t('mixed|not_in_use_in_questionnaire') }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ extractedValues(suggestion) ? icons.textBox : icons.emptyDatabase }}
                      </v-icon>
                      {{ extractedValues(suggestion) ? $t('mixed|extracted_data_available') : $t('mixed|no_extracted_data_available') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div
                v-else
                class="ml-5"
                style="font-size: 14px"
              >
                {{ $t('mixed|no_actual_suggestions') }}
              </div>
            </v-list-group>
            <v-list-group
              v-if="actualTabTemplateViewCases === 0"
              :value="true"
              dense
            >
              <v-icon
                slot="prependIcon"
                size="20"
              >
                mdi-help-box
              </v-icon>
              <template
                v-slot:activator
              >
                <v-list-item-title
                  class="ml-n8"
                  style="font-size: 16px; font-weight: bold;"
                >
                  <span style="white-space: normal;">{{ $t('templates|suggestions_added_in_questionnaire') }}</span>
                </v-list-item-title>
              </template>
              <div
                v-if="actualTemplate.questionnaire.fields.length"
              >
                <v-list-item
                  v-for="suggestion in questionnaireFields"
                  :key="suggestion.id"
                  three-line
                  dense
                  @click="showStatus(suggestion)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      :class="inUseInTemplate(suggestion) ? 'accent--text' : 'error--text'"
                    >
                      @{{ suggestion.customLabel ? suggestion.name + '[' + suggestion.customLabel + ']' : suggestion.label ? suggestion.label : suggestion.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                      class="mt-1"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ inUseInTemplate(suggestion) ? 'mdi-checkbox-marked-circle-outline' : icons.notInUseInQuestionairre }}
                      </v-icon>
                      {{ inUseInTemplate(suggestion) ? $t('mixed|in_use_in_template') : $t('mixed|not_in_use_in_template') }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ suggestion.value ? icons.check : icons.progressQuestion }}
                      </v-icon>
                      {{ suggestion.value ? $t('questionnaire|questionnaire_answer_available') : $t('questionnaire|waiting_for_response') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div
                v-else
                class="ml-5"
                style="font-size: 14px"
              >
                {{ $t('mixed|no_actual_suggestions') }}
              </div>
            </v-list-group>
          </v-list>
        </v-card>
        <v-card
          v-if="actualTabTemplateViewCases === 1"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <QuestionnaireSuggestionsPanel
            v-if="actualTemplate"
            :template-data="actualTemplate"
            :workbench="actualWorkbench"
            :options="{
              hasClose: false,
              inCase: true,
              inCaseModel: false,
              inTemplate: false,
              isVisibleDocuments: false,
              isVisibleSuggestions: true
            }"
            @addField="addNewQuestionnaireFieldInCase"
            @addAllUnusedFields="addNewFieldsInCaseQuestionnaire"
            @updateField="saveTemplate"
            @deleteField="deleteFieldInCaseQuestionnaire"
            @updateQuestionnaire="updateQuestionnaire"
            @share="shareTemplate"
            @stop-sharing="stopSharingQuestionnaire"
          />
        </v-card>
        <v-card
          v-if="actualTabTemplateViewCases === 2"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <FillingAssistentPanel
            v-if="actualTemplate"
            :edit="true"
            :language="actualTemplate.language"
            :questionairre="actualTemplate.questionnaire"
            :related-documents="actualWorkbench ? actualWorkbench.assignedDocuments : []"
            :template-id="actualTemplate._id"
            :template="actualTemplate"
            :workbench="actualWorkbench"
            :case-data="currentCase"
            :account="account"
          />
          <!-- :template-data="actualTemplate.templateData"
            :actual-template-data-json="actualTemplate.templateData.json" -->
        </v-card>
      </v-col>

      <!-- CASE VIEW -> info, participants, action buttons, workbenches -->
      <v-col
        cols="12"
        :sm="actualTemplate && account.accountType === 'lawyer' ? '8' : actualTemplate && account.accountType !== 'lawyer' ? '10' : '9'"
      >
        <v-card
          :height="($vuetify.breakpoint.height - 72).toString()"
          class="pa-2"
        >
          <v-row
            dense
          >
            <v-col
              cols="12"
              sm="5"
            >
              <v-card
                flat
              >
                <v-card-title>
                  <v-hover
                    v-slot="{ hover }"
                  >
                    <div
                      class="mt-n2"
                      style="min-width: 100%"
                    >
                      <input
                        v-model="currentCase.caseName"
                        class="pl-2 ml-n2 rounded input"
                        style="white-space:nowrap; text-overflow: ellipsis; min-width: 100%"
                        :style="hover && !flatFields.includes(currentCase.caseName) ? 'background-color: #eee; text-overflow: initial;' : flatFields.includes(currentCase.caseName) ? 'background-color: #CFD8DC' : ''"
                        @focus="flatFields.push(currentCase.caseName)"
                        @blur="updateCaseName(currentCase.caseName); flatFields.splice(0, 1)"
                      >
                    </div>
                  </v-hover>
                </v-card-title>
                <v-card-text>
                  <div class="mt-n2">
                    {{ $t('clients|client') }}:
                    <template v-if="currentCase && currentCase.client && !currentCase.client.realClient">
                      <span v-if="currentCase.client.clientType === 'individual'">{{ currentCase.client.clientData.given_names ? currentCase.client.clientData.given_names : 'not added' }} {{ currentCase.client.clientData.surname ? currentCase.client.clientData.surname : 'not added' }}</span>
                      <span v-if="currentCase.client.clientType === 'company'">{{ currentCase.client.clientData.company_name }}</span>
                    </template>
                    <template v-if="currentCase && currentCase.client && currentCase.client.realClient">
                      <span v-if="currentCase.client.clientType === 'individual'">{{ currentCase.client.clientData.given_names ? currentCase.client.clientData.given_names : 'not added' }} {{ currentCase.client.clientData.surname ? currentCase.client.clientData.surname : 'not added' }}</span>
                      <span v-if="currentCase.client.clientType === 'company'">{{ currentCase.client.clientData.company_name }}</span>
                    </template>
                    <template v-if="currentCase && !currentCase.client">
                      <v-icon
                        small
                        color="primary"
                        class="ml-1"
                        @click="assignNewClient()"
                      >
                        mdi-account-plus
                      </v-icon>
                    </template>
                  </div>
                  <div>
                    {{ $t('common|date_created') }}: {{ currentCase.createdAt | date }}
                  </div>
                  <div
                    v-if="account.accountType === 'lawyer'"
                  >
                    {{ $t('common|date_updated') }}: {{ currentCase.updatedAt | dateTime }}
                  </div>
                  <div>
                    {{ $t('common|created_by') }}: {{ currentCase.createdBy.email }}
                  </div>
                  <CaseProgress
                    :status="currentCase.status"
                    :current-case="currentCase"
                    :cols-progress-bar="8"
                    :cols-title="3"
                    :account="account"
                    style="flex-grow: 1; width: auto; max-width: 350px"
                    @update-progress="updateProgress"
                    @open-info-progress-dialog="openInfoProgressDialog"
                  />

                  <InfoProgressDialog
                    ref="InfoProgressDialog"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-card
                flat
              >
                <v-toolbar
                  dense
                  flat
                  height="35"
                  style="position: sticky; top: 0px; z-index: 1;"
                >
                  <v-subheader
                    class="ml-n7 mt-3"
                  >
                    {{ $t('cases|participants') }}
                  </v-subheader>
                </v-toolbar>
                <v-card-text
                  class="px-2 mt-n3"
                  style="max-height: 170px; overflow-y: auto;"
                >
                  <div
                    v-for="(participant, index) in sharedWithMembers"
                    :key="index"
                    class="mx-auto my-2"
                  >
                    <v-chip
                      outlined
                      small
                    >
                      <v-avatar
                        size="18px"
                        class="ml-n3 mr-1"
                      >
                        <img
                          v-if="participant && participant.avatar"
                          alt="Avatar"
                          :src="`${cfg.baseURL}downloadAvatar/${participant._id}/${participant.avatar.raw.filename}`"
                        >
                        <v-avatar
                          v-else
                        >
                          <v-icon
                            v-if="participant.type === 'account'"
                            color="primary"
                            small
                          >
                            mdi-account
                          </v-icon>
                          <v-icon
                            v-if="participant.type === 'company'"
                            color="primary"
                            small
                          >
                            mdi-domain
                          </v-icon>
                          <v-icon
                            v-if="participant.type === 'group'"
                            color="primary"
                            small
                          >
                            mdi-account-group
                          </v-icon>
                        </v-avatar>
                      </v-avatar>
                      <span
                        class="text-truncate"
                        style="font-size: 12px;"
                      >
                        {{ participant.participantName }}
                      </span>
                    </v-chip>
                  </div>
                </v-card-text>
                <v-toolbar
                  dense
                  flat
                  height="35"
                  style="position: sticky; bottom: 0px; z-index: 1;"
                >
                  <v-chip
                    v-if="account.accountType === 'lawyer'"
                    outlined
                    rounded
                    color="primary"
                    small
                    class="ml-n2 mt-n2"
                    @click="openShareCaseDialog"
                  >
                    <v-icon
                      left
                      small
                      style="position: absolute; left 3px;"
                    >
                      mdi-plus
                    </v-icon>
                    <span class="ml-3">{{ $t('actions|add_new') }}</span>
                  </v-chip>

                  <v-chip
                    v-if="account.accountType === 'lawyer'"
                    outlined
                    rounded
                    color="primary"
                    small
                    class="ml-2 mt-n2"
                    :disabled="currentCase.sharedWith.accounts.length + currentCase.sharedWith.company.length + currentCase.sharedWith.groups.length === 1"
                    @click="removeCase(currentCase)"
                  >
                    <v-icon
                      left
                      small
                      style="position: absolute; left 6px;"
                    >
                      mdi-minus
                    </v-icon>
                    <span class="ml-3">{{ $t('actions|delete') }}</span>
                  </v-chip>
                </v-toolbar>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-card
                flat
                height="208"
                class="pt-3"
              >
                <v-card-text>
                  <v-row
                    justify="end"
                  >
                    <v-menu
                      v-model="statusMenu"
                      offset-y
                      :disabled="account.accountType !== 'lawyer'"
                      @click.native.stop.prevent
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="account.accountType === 'lawyer'"
                          color="primary"
                          outlined
                          dark
                          x-small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            small
                            left
                          >
                            {{ getTheRightIcon(currentCase.status) }}
                          </v-icon>
                          {{ getTranslatedStatus(currentCase.status) }}
                          <v-icon
                            v-if="account.accountType === 'lawyer'"
                            class="ml-1"
                            small
                          >
                            {{ icons.arrowDown }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="status in caseStatuses"
                          :key="status.value"
                          :active-class="status.value === currentCase.status ? 'active-item' : ''"
                          :class="{active: status.value === currentCase.status}"
                          @click.stop="changeStatus(currentCase, status); statusMenu = false"
                        >
                          <v-icon
                            class="ml-n2 mr-2 mt-1"
                            size="18"
                            :color="status.color"
                          >
                            {{ status.icon }}
                          </v-icon>
                          <v-list-item-title>{{ getTranslatedStatus(status.text) }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <div v-if="currentCase.pinned.includes(account._id)">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            icon
                            small
                            class="mt-n1"
                            v-on="on"
                            @click.stop="removePinFromDashboard(currentCase)"
                          >
                            <v-icon>
                              mdi-pin
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('actions|remove_from_pinboard') }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            icon
                            small
                            class="mt-n1"
                            v-on="on"
                            @click.stop="pinToDashboard(currentCase)"
                          >
                            <v-icon>
                              mdi-pin-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('actions|add_to_pinboard') }}</span>
                      </v-tooltip>
                    </div>
                  </v-row>
                  <v-row
                    dense
                    justify="end"
                    class="mt-4"
                  >
                    <div
                      v-if="account.accountType === 'lawyer'"
                      class="d-flex flex-column"
                    >
                      <v-btn
                        rounded
                        outlined
                        color="primary"
                        x-small
                        width="100%"
                        class="mb-2"
                        @click="exportCase(currentCase)"
                      >
                        <v-icon
                          small
                          style="position: absolute; left: 3px;"
                        >
                          mdi-download
                        </v-icon>
                        {{ $t('actions|export_case') }}
                      </v-btn>
                      <v-btn
                        rounded
                        outlined
                        color="primary"
                        x-small
                        width="100%"
                        class="mb-2"
                        @click="generateCaseModel"
                      >
                        <v-icon
                          small
                          style="position: absolute; left: 3px;"
                        >
                          mdi-briefcase-edit
                        </v-icon>
                        <span class="ml-5">{{ $t('actions|generate_case_model') }}</span>
                      </v-btn>
                      <v-btn
                        rounded
                        outlined
                        color="primary"
                        x-small
                        width="100%"
                        class="mb-2"
                        @click="openCaseTimeline"
                      >
                        <v-icon
                          small
                          style="position: absolute; left: 3px;"
                        >
                          mdi-timeline
                        </v-icon>
                        {{ $t('cases|case_timeline') }}
                      </v-btn>
                      <v-btn
                        rounded
                        outlined
                        color="primary"
                        x-small
                        width="100%"
                        @click="getCaseSharedWithLength(currentCase) <= 1 ? deleteCase(currentCase) : removeCase(currentCase)"
                      >
                        <v-icon
                          small
                          style="position: absolute; left: 3px;"
                        >
                          mdi-trash-can-outline
                        </v-icon>
                        {{ $t('actions|delete_case') }}
                      </v-btn>
                      <!-- <v-btn
                        v-if="account.accountType === 'lawyer'"
                        rounded
                        outlined
                        color="primary"
                        x-small
                        width="100%"
                        max-width="250px"
                        class="mb-1"
                        @click="generalQDialog = true"
                      >
                        All Questionnaires
                        <v-icon
                          x-small
                          right
                        >
                          mdi-help-box
                        </v-icon>
                      </v-btn> -->
                      <!-- <GeneralQuestionairreCardCases
                        v-if="account.accountType === 'lawyer'"
                        :height="'100'"
                        :max-width="'250px'"
                        @open-general-questionairre="generalQDialog = true"
                      /> -->
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="currentCase"
            class="ma-1"
          >
            <v-card
              flat
              :height="($vuetify.breakpoint.height - 330).toString()"
              width="100%"
            >
              <div class="d-flex align-center pa-2">
                <v-tabs
                  v-model="selectedWbTab"
                  height="35"
                  active-class="white"
                  :slider-color="currentCase.workbenches.length === 1 ? 'white' : 'accent'"
                  next-icon="mdi-arrow-right"
                  prev-icon="mdi-arrow-left"
                  show-arrows
                  grow
                  style="max-width: 90%; overflow: auto;"
                >
                  <v-tab
                    v-for="(workbench, idx) in currentCase.workbenches"
                    :key="workbench._id"
                    class="tabs-text"
                    :style="currentCase.workbenches.length === 1 ? 'color: black; opacity: 1.0; pointer-events: none;' : ''"
                    :name="workbench.name"
                    :to="`/cases/${$route.params._id}/${workbench._id}`"
                    @click="setActualWorkbench(idx)"
                  >
                    {{ workbench.name }}
                  </v-tab>
                </v-tabs>

                <!-- Edit Workbenches && Add Workbench Menu -->
                <v-icon
                  v-if="account.accountType === 'lawyer'"
                  color="primary"
                  class="mx-2"
                  @click="addWorkbench"
                >
                  mdi-plus
                </v-icon>

                <v-menu
                  v-if="account.accountType === 'lawyer'"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-dots-horizontal
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in workbenchMenuItems"
                      :key="index"
                      @click="triggerAction(item.action)"
                    >
                      <v-icon
                        small
                      >
                        {{ item.icon }}
                      </v-icon>
                      <v-list-item-title class="ml-2">
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-slide-y-transition
                mode="in-out"
                :hide-on-leave="true"
              >
                <router-view
                  v-if="$route.name === 'CasesViewWorkbench'"
                  v-show="$route.name === 'CasesViewWorkbench'"
                />
              </v-slide-y-transition>
            </v-card>
            <transition
              name="fade"
              mode="out-in"
            >
              <router-view
                v-if="$route.name === 'CasesViewTemplate'"
              />
            </transition>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        :sm="actualTemplate ? '2' : '3'"
      >
        <!-- Case documents panel right -->
        <v-card
          v-if="$route.name === 'CasesViewWorkbench'"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <v-list
            dense
            expand
            :max-height="($vuetify.breakpoint.height - 117).toString()"
            style="overflow-y: auto;"
          >
            <v-list-group
              :value="true"
              prepend-icon="mdi-file"
              class="mt-n2"
            >
              <v-icon
                slot="prependIcon"
                color="primary"
                class="mt-1"
              >
                mdi-file
              </v-icon>
              <div />
              <template v-slot:activator>
                <v-list-item-title
                  class="ml-n4"
                >
                  <span style="font-size: 17px; font-weight: bold; white-space: normal;">{{ $t('mixed|case_documents') }}</span><br>
                </v-list-item-title>
              </template>
              <!-- :style="`${dragAreaDocsCase ? 'outline-style: dashed; margin-left: 10px; margin-right: 10px;' : ''}`" -->
              <div v-if="account.accountType === 'lawyer'">
                <draggable
                  class="list-group"
                  :list="_documents"
                  handle=".handle-icon"
                  :group="{ name: 'docs', pull: 'clone', put: false }"
                  @change="onChangeDocumentList"
                  @start="onDragStartToWorkbench()"
                  @end="onDragEndToWorkbench()"
                >
                  <div
                    v-for="(doc, index) in _documents"
                    :key="index"
                    class="list-group-item"
                  >
                    <DocumentListItemCasePanelRight
                      :document="doc"
                      :index="index"
                      :in-case="true"
                      @open-doc="openDocument(doc)"
                      @deleteAction="removeCaseDocument(doc)"
                    />
                  </div>
                </draggable>
                <v-chip
                  color="primary"
                  outlined
                  rounded
                  small
                  class="mt-4 chip-btn"
                  style="width: 40%;"
                  @click.stop="assignCaseDocument"
                >
                  <v-icon
                    small
                    class="mr-1"
                  >
                    mdi-plus
                  </v-icon>
                  {{ $t('actions|add_new') }}
                </v-chip>
                <div v-if="!_documents.length">
                  <v-card-text>
                    {{ $t('expressions|no_available_documents') }}
                  </v-card-text>
                </div>
              </div>

              <div v-if="account.accountType !== 'lawyer'">
                <!-- NOT WORKING WITH DRAGGABLE -->
                <document-list
                  :documents="clientDocuments"
                  :search="searchInput"
                  :search-label="$t('actions|search_documents')"
                  :loading="documentsPanelLoading"
                  :is-flat="true"
                  :in-case="true"
                  :right-panel-case="true"
                  class="mt-3"
                  :style="`${dragAreaDocsCase ? 'outline-style: dashed; margin-left: 10px; margin-right: 10px; margin-top: 30px;' : ''}`"
                  @dragCaseDoc="dragAreaDocsCase = true"
                  @stopDragCaseDoc="dragAreaDocsCase = false"
                  @search="onSearchInput"
                  @click="openDocument"
                  @delete="removeCaseDocument"
                >
                  <template slot="actions">
                    <v-chip
                      color="primary"
                      outlined
                      rounded
                      small
                      class="chip-btn"
                      @click.stop="assignCaseDocument"
                    >
                      <v-icon
                        small
                        class="mr-1"
                      >
                        mdi-plus
                      </v-icon>
                      {{ $t('actions|add_new') }}
                    </v-chip>
                  </template>
                </document-list>
              </div>
            </v-list-group>

            <!-- Required case documents panel right -->
            <v-list-group
              v-if="account.accountType === 'lawyer'"
              :value="true"
              width="100%"
              dense
              prepend-icon="mdi-file-hidden"
              class="mt-4"
              style="overflow-y: auto;"
            >
              <!-- empty div needed -> otherwise activator slot not working - vuetify bug -->
              <div />
              <div slot="prepend">
                <v-icon
                  color="primary"
                >
                  mdi-file-hidden
                </v-icon>
              </div>
              <template v-slot:activator>
                <v-list-item-title
                  class="ml-n4"
                >
                  <span style="font-size: 17px; font-weight: bold; white-space: normal;">{{ $t('mixed|required_case_documents') }}</span><br>
                </v-list-item-title>
              </template>
              <draggable
                class="dragArea list-group"
                ghost-class="ghost"
                :list="currentCase.requiredCaseDocuments"
                handle=".handle-icon"
                :group="{ name: 'wbReqDocs', pull: 'clone', put: true }"
                :style="`${dragAreaRequiredDocsCase ? 'outline-style: dashed; margin-left: 10px; margin-right: 10px;' : ''}`"
                @change="onDragChange"
                @start="onDragStart()"
                @end="onDragEnd()"
              >
                <div
                  v-for="(suggestion, index) in currentCase.requiredCaseDocuments"
                  :key="index"
                >
                  <v-card
                    flat
                    rounded
                    outlined
                    min-height="60px"
                    class="my-4 mx-2 d-flex rounded-lg"
                  >
                    <v-row
                      no-gutters
                    >
                      <v-col
                        sm="2"
                        md="1"
                        lg="1"
                        xs="2"
                      >
                        <v-card
                          class="rounded-r-0"
                          flat
                          height="100%"
                          color="lightBackground"
                          :style="`border-top-left-radius: 10px; border-bottom-left-radius: 10px; border: 1px solid ${$vuetify.theme.themes.light.background};`"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                class="handle-icon centered-icon"
                                style="cursor: grab;"
                                size="34"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ icons.drag }}
                              </v-icon>
                            </template>
                            <span>{{ $t('actions|drag_document_to_workbench') }}</span>
                          </v-tooltip>
                        </v-card>
                      </v-col>
                      <v-col cols="1">
                        <v-icon
                          color="primary"
                          class="ml-2 mt-4"
                        >
                          mdi-file-outline
                        </v-icon>
                      </v-col>
                      <v-col
                        sm="8"
                        md="9"
                        lg="9"
                      >
                        <v-card
                          flat
                          class="rounded-l-0"
                        >
                          <div
                            class="grey--text text--darken-2 ml-3 mt-2"
                            style="font-size: 14px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                          >
                            {{ $t('documents|required_document') }}
                          </div>
                          <div
                            class="primary--text ml-3 mt-1"
                            :style="`font-size: 14px; text-overflow: ellipsis; overflow: hidden; white-space: normal;`"
                          >
                            {{ translateFileInputField(suggestion) }}
                          </div>
                        </v-card>
                      </v-col>
                      <v-col
                        sm="2"
                        md="1"
                        lg="1"
                      >
                        <v-card
                          class="rounded-l-0"
                          flat
                          height="100%"
                          color="lightBackground"
                          :style="`border-top-right-radius: 10px; border-bottom-right-radius: 10px; border: 1px solid ${$vuetify.theme.themes.light.background};`"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                :color="checkIfSuggestionTypeAvailableInDocuments(suggestion) ? 'success' : 'grey'"
                                v-bind="attrs"
                                size="20"
                                class="ml-1 mt-1"
                                v-on="on"
                              >
                                mdi-check-circle
                              </v-icon>
                            </template>
                            <span>
                              {{ checkIfSuggestionTypeAvailableInDocuments(suggestion) ? $t('expressions|document_type_available_in_wb') : $t('expressions|document_type_not_uploaded') }}
                            </span>
                          </v-tooltip>
                          <v-menu
                            offset-y
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                icon
                                size="20"
                                class="ml-1 mt-1"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ icons.cog }}
                              </v-icon>
                            </template>
                            <v-list>
                              <v-list-item>
                                <v-icon
                                  color="primary"
                                  class="mr-2"
                                >
                                  mdi-link-off
                                </v-icon>
                                <v-list-item-title
                                  style="cursor: pointer;"
                                  @click="removeRequiredDocument(suggestion)"
                                >
                                  {{ $t('actions|remove') }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </draggable>
              <div
                v-if="!currentCase.requiredCaseDocuments.length"
                class="ml-5 mt-4"
                style="font-size: 15px"
              >
                {{ $t('mixed|no_required_workbench_documents') }}
              </div>
              <v-chip
                color="primary"
                outlined
                rounded
                small
                class="mt-4 chip-btn"
                style="width: 40%;"
                @click.stop="assignRequiredCaseDocument"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  mdi-plus
                </v-icon>
                {{ $t('actions|add_new') }}
              </v-chip>
            </v-list-group>
          </v-list>
        </v-card>

        <!-- Template View right side panel -->
        <!-- Comments List -->
        <v-card
          v-if="$route.name === 'CasesViewTemplate' && actualTabTemplateViewCases === 0"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <v-list
            :max-height="account.accountType === 'lawyer' ? ($vuetify.breakpoint.height - 81).toString() : ($vuetify.breakpoint.height - 81).toString()"
            style="overflow-y: auto;"
            expand
          >
            <v-list-group
              v-if="actualTemplate"
              :value="true"
              prepend-icon="mdi-comment-text-outline"
              class="mt-n2"
            >
              <template v-slot:activator>
                <v-list-item-title
                  class="ml-n5"
                  style="font-size: 16px; font-weight: bold;"
                >
                  {{ $t('common|comments') }}
                </v-list-item-title>
              </template>
              <div v-if="actualCommentsEditorStorage.length">
                <v-list-item
                  v-for="c in actualCommentsEditorStorage"
                  id="commentText"
                  :key="c.id"
                >
                  <CommentCard
                    :id="c.attrs.id"
                    :key="componentKey"
                    :comment="c.attrs"
                    :case-data="currentCase"
                    :workbench="actualWorkbench"
                    :template="actualTemplate"
                    :elevation-effect="elevationEffect"
                    :comment-id="commentId"
                  />
                </v-list-item>
              </div>
              <div
                v-else
                class="ml-5"
                style="font-size: 15px"
              >
                {{ $t('expressions|no_actual_comments') }}
              </div>
            </v-list-group>

            <!-- Required Workbench Documents List -->
            <v-list-group
              v-if="actualTemplate && account.accountType === 'lawyer'"
              :value="true"
              dense
              class="mt-1"
            >
              <v-icon
                slot="prependIcon"
                class="mr-n6"
              >
                mdi-file-outline
              </v-icon>
              <template
                v-slot:activator
              >
                <v-list-item-title
                  class="ml-n5"
                  style="white-space: normal;"
                >
                  <span style="font-size: 16px; font-weight: bold;">{{ $t('documents|required_documents') }}</span><br>
                </v-list-item-title>
              </template>
              <div v-if="actualWorkbench.requiredDocuments.length">
                <v-list-item
                  v-for="suggestion in actualWorkbench.requiredDocuments"
                  :key="suggestion.id"
                  three-line
                  dense
                  @click="scrollToQuestionnaireField(suggestion)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="primary--text"
                    >
                      {{ translateFileInputField(suggestion) }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ inUseRequiredDocumentsInQuestionnaire(suggestion) ? 'mdi-checkbox-marked-circle-outline' : icons.notInUseInQuestionairre }}
                      </v-icon>
                      {{ inUseRequiredDocumentsInQuestionnaire(suggestion) ? $t('mixed|in_use_in_questionnaire') : $t('mixed|not_in_use_in_questionnaire') }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ icons.notUploaded }}
                      </v-icon>
                      {{ $t('documents|not_uploaded_by_client') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div
                v-else
                class="ml-5"
                style="font-size: 15px"
              >
                {{ $t('mixed|no_required_workbench_documents') }}
              </div>
            </v-list-group>
          </v-list>
        </v-card>

        <!-- Questionnaire right side panel -->
        <v-card
          v-if="$route.name === 'CasesViewTemplate' && actualTabTemplateViewCases === 1"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <QuestionnaireSuggestionsPanel
            v-if="actualTemplate"
            :template-data="actualTemplate"
            :workbench="actualWorkbench"
            :options="{
              hasClose: false,
              inCase: true,
              inCaseModel: false,
              inTemplate: false,
              isVisibleDocuments: true,
              isVisibleSuggestions: false
            }"
            @addField="addNewQuestionnaireFieldInCase"
            @addAllUnusedFields="addNewFieldsInCaseQuestionnaire"
            @updateField="saveTemplate"
            @deleteField="deleteFieldInCaseQuestionnaire"
            @updateQuestionnaire="updateQuestionnaire"
            @add-new-document-in-template-questionnaire="onAddNewDocument"
            @share="shareTemplate"
            @stop-sharing="stopSharingQuestionnaire"
          />
        </v-card>
      </v-col>
    </v-row>

    <dialog-base
      v-model="addNewRequiredDocDialog"
      :toolbar-active="toolbarActive"
      :component-key="newRequiredDocDialogComponentKey"
      :style-header="'position: sticky; top: 0px; z-index: 1;'"
      :style-text-field="'position: sticky; top: 63px; z-index: 1; background-color: white;'"
      :actions-style="'position: sticky; bottom: 0px; z-index: 1; background-color: white;'"
      @searching-field="search"
      @dialog-closed="resetRequiredDocDialog"
    >
      <template
        v-slot:header
      >
        {{ $t('actions|select_new_required_document') }}
      </template>
      <template>
        <v-expand-transition>
          <v-list v-show="!customField">
            <v-list-item-group
              v-model="activeReqDocuments"
              multiple
              dense
            >
              <v-list-item
                @click="customField = true; toolbarActive = false; newRequiredDocDialogComponentKey++;"
              >
                <template>
                  <v-list-item-icon>
                    <v-icon>
                      mdi-file-hidden
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('common|custom') }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-icon>
                      {{ icons.cog }}
                    </v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <template v-for="(item, i) in reqDocumentFields">
                <v-divider
                  v-if="i !== 0 && item.name"
                  :key="`divider-${i}`"
                />

                <v-list-item
                  v-if="item.name"
                  :key="`item-${i}`"
                  :value="item"
                  active-class="deep-purple--text accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-icon>
                      <v-icon>
                        mdi-file-hidden
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title
                        style="white-space: normal;"
                      >
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="deep-purple accent-4"
                      />
                      <v-icon
                        v-if="item.name === 'Custom'"
                      >
                        {{ icons.cog }}
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-expand-transition>
        <v-text-field
          v-show="customField"
          v-model="customFieldInput"
          outlined
          dense
          :label="$t('actions|add_document_type')"
          class="mt-4"
        />
      </template>
      <template
        v-slot:actions
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-if="customField === false"
              outlined
              color="primary"
              :attrs="attrs"
              v-on="on"
            >
              {{ $t('actions|change_language') }}
              <v-icon>
                mdi-menu-down
              </v-icon>
            </v-chip>
          </template>
          <v-list
            v-model="selectedLanguage"
          >
            <v-list-item
              v-for="(item, index) in settings.activeLanguages"
              :key="index"
              @click="selectedLanguage = item"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-if="customField"
          text
          small
          min-width="150"
          @click="customField = false; customFieldInput = null; toolbarActive = true; newRequiredDocDialogComponentKey++;"
        >
          {{ $t('actions|back') }}
        </v-btn>
        <v-spacer />
        <!--:disabled="disabledRule"-->
        <v-btn
          rounded
          dense
          small
          color="primary"
          min-width="150"
          @click="customField ? addCustomField(customFieldInput) : addDocumentSuggestionsToCase(activeReqDocuments)"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </template>
    </dialog-base>

    <FileView
      ref="fileView"
      :case-data="currentCase"
      :in-case="true"
    />
    <EditCaseWorkbenches
      v-if="currentCase"
      v-model="editWorkbenchesDialog"
      :workbenches="currentCase.workbenches"
      :loading="loadingDeleteWorkbench"
      @update="updateWorkbenches"
      @delete="deleteWorkbench"
    />
    <ShareCase
      ref="ShareCase"
      :current-case="currentCase"
    />
    <AddCaseModel
      ref="AddCaseModel"
      :case-model-data="caseModelData"
      :from-case="true"
    />
    <CaseTimeline
      ref="CaseTimeline"
      :current-case="currentCase"
    />
    <NewCaseClientDialog
      ref="NewCaseClientDialog"
      @add-new-client="addNewClient"
    />
    <Editor
      v-show="false"
      ref="Editor"
    />
    <!-- <v-dialog
      v-model="generalQDialog"
      keydown
      max-width="1200px"
      transition="dialog-bottom-transition"
      style="position: relative;"
    >
      <GeneralQuestionnaireForm
        v-if="account.accountType === 'lawyer'"
        :key="generalQDialog"
        :template-data="allCurrentTemplateData"
        :workbench="actualWorkbench"
        :current-case="currentCase"
        :case-id="currentCase._id"
        :options="{
          inCase: true
        }"
        @close="generalQDialog = false"
        @save="$emit('save-template')"
        @addField="$emit('add-field')"
        @updateField="$emit('update-field')"
        @deleteField="$emit('delete-field')"
        @closeTemplate="$emit('closeTemplate')"
        @update-case-questionairre="updateQuestionnaire"
      />
    </v-dialog> -->
    <!-- <GeneralQuestionnaireFinalForm
      v-model="fullQuestionnaireFormDialog"
      :workbench="actualWorkbench"
      @updateQuestionnaire="updateQuestionnaire"
    /> -->
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { caseStatuses } from '@/utils/index'
import { EventBus } from '@/utils/EventBus'
import { uniqBy } from '@/utils/utils'
import { v4 as uuidv4 } from 'uuid'
import { saveAs } from 'file-saver'
import cfg from '@/config'
import draggable from 'vuedraggable'
import exportTemplate from '../../pages/TemplateEditor/exportDocx.js'
import templateViewMixin from './CaseMixins/templateViewMixin'
import generalMixin from '@/utils/generalMixin'
import CaseCardMixin from './CaseMixins/CaseCardMixin'
import CaseProgress from './CaseProgress.vue'
import FileView from '@/components/dialogs/FileView.vue'
import CommentCard from '../../pages/TemplateEditor/CommentCard.vue'
import EditCaseWorkbenches from './EditCaseWorkbenches.vue'
import ShareCase from '../../components/dialogs/ShareCase.vue'
import InfoProgressDialog from '../../components/dialogs/InfoProgressDialog.vue'
import AddCaseModel from '../../components/dialogs/AddCaseModel.vue'
import CaseTimeline from './CaseTimeline.vue'
import QuestionnaireSuggestionsPanel from '../../components/forms/QuestionnaireFieldEdit/QuestionnaireSuggestionsPanel.vue'
import FillingAssistentPanel from './FillingAssistentPanel.vue'
import JSZip from 'jszip'
import Editor from '../TemplateEditor/Editor.vue'
import NewCaseClientDialog from '../../components/dialogs/NewCaseClientDialog.vue'
import DocumentListItemCasePanelRight from '../../components/partials/DocumentListItemCasePanelRight.vue'
import {
  mdiTimerSand,
  mdiCheckCircleOutline,
  mdiPlus,
  mdiTrashCanOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiPinOutline,
  mdiFile,
  mdiClose,
  mdiTextBoxSearchOutline,
  mdiCommentOffOutline,
  mdiDatabaseOffOutline,
  mdiUploadOffOutline,
  mdiPencil,
  mdiCog,
  mdiDrag
} from '@mdi/js'
// import GeneralQuestionnaireFinalForm from '@/components/forms/GeneralQuestionnaireFinalForm.vue'
// import FullQuestionnaireForm from './FullQuestionnaireForm.vue'
// import GeneralQuestionairreCardCases from './GeneralQuestionairreCardCases.vue'
// import GeneralQuestionnaireForm from '../../components/forms/GeneralQuestionnaireForm.vue'

export default {
  components: {
    // FullQuestionnaireForm,
    // GeneralQuestionairreCardCases,
    // GeneralQuestionnaireFinalForm,
    // GeneralQuestionnaireForm,
    CaseProgress,
    FileView,
    CommentCard,
    EditCaseWorkbenches,
    ShareCase,
    InfoProgressDialog,
    NewCaseClientDialog,
    AddCaseModel,
    CaseTimeline,
    QuestionnaireSuggestionsPanel,
    FillingAssistentPanel,
    Editor,
    draggable,
    DocumentListItemCasePanelRight
  },
  mixins: [CaseCardMixin, templateViewMixin, generalMixin],
  data () {
    return {
      workbenchMenuItems: [
        {
          text: this.$t('actions|edit_workbenches'),
          icon: mdiPencil,
          action: 'editWorkbenchesDialog'
        },
        {
          text: this.$t('actions|add_workbench'),
          icon: mdiPlus,
          action: 'addWorkbench'
        }
      ],
      icons: {
        trash: mdiTrashCanOutline,
        arrowDown: mdiChevronDown,
        arrowUp: mdiChevronUp,
        pin: mdiPinOutline,
        file: mdiFile,
        close: mdiClose,
        textBox: mdiTextBoxSearchOutline,
        notInUseInQuestionairre: mdiCommentOffOutline,
        emptyDatabase: mdiDatabaseOffOutline,
        notUploaded: mdiUploadOffOutline,
        check: mdiCheckCircleOutline,
        progressQuestion: mdiTimerSand,
        cog: mdiCog,
        drag: mdiDrag
      },
      loading: true,
      loadingDeleteWorkbench: false,
      selectedWbTab: localStorage.getItem('actual-case-workbench') || null,
      actualTabTemplateViewCases: 0,
      statusMenu: false,
      caseStatuses,
      rightPanelExpand: 'caseDocuments',
      templateIsSeleted: false,
      foundDocuments: [],
      accountMembers: [],
      flatFields: [],
      searchInput: '',
      searchInputGeneralSearch: '',
      searchText: null,
      documentsPanelLoading: false,
      elevationEffect: false,
      commentId: '',
      editWorkbenchesDialog: false,
      fullQuestionnaireFormDialog: false,
      generalQDialog: false,
      caseModelData: null,
      userRole: null,
      cfg,
      componentKey: 1,
      templateSuggestionsComponentKey: 1,
      addNewRequiredDocDialog: false,
      activeReqDocuments: [],
      customField: false,
      toolbarActive: true,
      customFieldInput: null,
      selectedLanguage: null,
      dragAreaRequiredDocsCase: false,
      dragAreaDocsCase: false,
      newRequiredDocDialogComponentKey: 1
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases,
      account: state => state.account.account,
      company: state => state.company.company,
      documents: state => state.documents.documents,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments,
      folders: state => state.documents.folders,
      companyFolders: state => state.companyDocuments.companyFolders,
      groupFolders: state => state.groupDocuments.groupFolders,
      actualCommentsEditorStorage: state => state.editorStore.actualCommentsEditorStorage,
      actualSuggestionsEditorStorage: state => state.editorStore.actualSuggestionsEditorStorage
    }),
    currentCase () {
      if (!this.cases || !this.companyCases || !this.groupCases) return []
      const _currentCase = this.cases.find(c => c._id === this.$route.params._id) || this.companyCases.find(c => c._id === this.$route.params._id) || this.groupCases.find(c => c._id === this.$route.params._id)
      return _currentCase
    },
    actualWorkbench () {
      return this.currentCase.workbenches.find(w => w._id === this.$route.params.workbenchId)
    },
    actualCaseViewRoute () {
      let route
      if (this.actualWorkbench) {
        route = `/cases/${this.$route.params._id}/${this.actualWorkbench._id}`
      }
      return route
    },
    actualTemplate () {
      let template
      if (this.actualWorkbench) {
        template = this.actualWorkbench.templates.find(t => t._id === this.$route.params.templateId)
      }
      return template
    },
    actualLanguage () {
      return localStorage.getItem('preferedLanguage')
    },
    questionnaireFields () {
      return this.actualTemplate.questionnaire.fields.filter(field => field.fieldType !== 'file-input')
    },
    actualTemplateAndQuestionairreSuggestions () {
      let suggestions = []
      let finalFilter = []
      if (this.actualSuggestionsEditorStorage.length) {
        this.actualSuggestionsEditorStorage.forEach(element => {
          suggestions.push(element.attrs)
        })
      }
      if (this.actualTemplate.questionnaire.fields && this.actualTemplate.questionnaire.fields.length) {
        this.actualTemplate.questionnaire.fields.forEach(field => {
          if (field.fieldType !== 'file-input') {
            suggestions.push(field)
          }
        })
      }
      if (suggestions.length) {
        finalFilter = uniqBy(suggestions, 'id')
      }
      return finalFilter
    },
    requestedDocumentsInQuestionairre () {
      let requestedDocs = []
      if (this.actualTemplate.questionnaire.fields && this.actualTemplate.questionnaire.fields.length) {
        this.actualTemplate.questionnaire.fields.forEach(field => {
          if (field.fieldType === 'file-input') {
            requestedDocs.push(field)
          }
        })
      }
      return requestedDocs
    },
    allCurrentTemplateData () {
      return this.currentCase.workbenches.reduce((acc, w) => {
        acc.push(
          ...w.templates
        )
        return acc
      }, [])
    },
    _documents () {
      if (this.searchInput === '' || !this.searchInput) return this.currentCase.documents ? this.currentCase.documents : []
      if (this.searchInput && this.searchInput.length) {
        return this.currentCase.documents.filter((doc) => {
          return doc.raw.originalname.toLowerCase().includes(this.searchInput.toLowerCase())
        })
      }
      return this.currentCase.documents
    },
    clientDocuments () {
      let docsToReturn = []
      this.currentCase.documents.forEach(doc => {
        const mactchId = doc.sharedWith.accounts.find(id => id === this.currentCase.client.clientAccount._id)
        if (mactchId) {
          docsToReturn.push(doc)
        }
      })
      if (this.searchInput && this.searchInput.length) {
        return docsToReturn.filter((doc) => {
          return doc.raw.originalname.toLowerCase().includes(this.searchInput.toLowerCase())
        })
      }
      return docsToReturn
    },
    sharedWithMembers () {
      const arr = []
      if (this.currentCase.sharedWith.accounts.length) {
        this.currentCase.sharedWith.accounts.forEach((acc) => {
          if (acc.accountType === 'lawyer') {
            const participant = {
              _id: acc._id,
              participantName: acc.accountData.firstName && acc.accountData.lastName ? acc.accountData.firstName + ' ' + acc.accountData.lastName : acc.accountName,
              avatar: acc.avatar,
              type: 'account'
            }
            arr.push(participant)
          }
          if (acc.accountType === 'individual') {
            const participant = {
              _id: acc._id,
              participantName: acc.accountData.firstName && acc.accountData.lastName ? acc.accountData.firstName + ' ' + acc.accountData.lastName : acc.accountName,
              avatar: acc.avatar,
              type: 'account'
            }
            arr.push(participant)
          }
          if (acc.accountType === 'company') {
            const participant = {
              _id: acc._id,
              participantName: acc.accountData.companyName && acc.accountData.companyType ? acc.accountData.companyName + ' ' + acc.accountData.companyType : acc.accountData.companyName,
              avatar: acc.avatar,
              type: 'account'
            }
            arr.push(participant)
          }
        })
      }
      if (this.currentCase.sharedWith.company.length) {
        this.currentCase.sharedWith.company.forEach((c) => {
          const participant = {
            participantName: c.companyData.companyName,
            avatar: null,
            type: 'company'
          }
          arr.push(participant)
        })
      }
      if (this.currentCase.sharedWith.groups.length) {
        this.currentCase.sharedWith.groups.forEach((g) => {
          const participant = {
            participantName: g.groupName,
            avatar: null,
            type: 'group'
          }
          arr.push(participant)
        })
      }
      return arr
    },
    filteredGroupDocuments () {
      let groupDocs = []
      const role = this.verifyUserRole(this.company, this.account)
      if (role && role === 'singleAcc') return
      if (this.company) {
        this.company.groups.forEach(group => {
          const index = group.groupMembers.findIndex(m => m._id === this.account._id)
          if (index !== -1) {
            this.groupDocuments.forEach(doc => {
              doc.sharedWith.groups.find(g => g._id === group._id)
              if (doc) {
                groupDocs.push(doc)
              }
            })
          }
        })
      }
      return groupDocs
    },
    reqDocumentFields () {
      if (!this.settings || !this.settings.fields) return []
      const arr = Object.keys(this.settings.fields).map((key) => {
        return {
          id: uuidv4(),
          name: this.selectedLanguage ? this.settings.fields[key].label[this.selectedLanguage] : this.settings.fields[key].label[this.actualLanguage],
          fieldType: this.settings.fields[key].type,
          fieldKey: key,
          key: key
        }
      })
      let onlyDocTypeArr = arr.filter(field => field.fieldType === 'file-input' && field.name !== 'Custom')
      let filteredArr = onlyDocTypeArr
      const filterItems = (arr, query) => {
        return arr.filter((el) => {
          if (el.name && Array.isArray(el.name)) {
            el.name.forEach(_name => {
              return _name.toLowerCase().indexOf(query.toLowerCase()) !== -1
            })
          } else {
            if (el.name) return el.name.toLowerCase().includes(query.toLowerCase())
          }
        })
      }
      if (this.searchText && this.searchText.length) {
        filteredArr = filterItems(onlyDocTypeArr, this.searchText)
      }
      return filteredArr
    },
    disabledRule () {
      if (!this.customField && !this.activeReqDocuments.length) {
        return true
      } else if (this.customField && this.customFieldInput === null) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    $route (to, from) {
      if (this.actualCaseViewRoute && to.fullPath === this.actualCaseViewRoute) {
        this.openLeftDrawerMenu()
      }
      if (this.$route.params.templateId && this.account.accountType === 'lawyer') {
        this.closeLeftDrawerMenu()
      }
    }
  },
  updated () {
    if (this.$route.params.templateId && this.account.accountType === 'lawyer') {
      this.closeLeftDrawerMenu()
    }
    // if (!this.$route.params.workbenchId && this.currentCase && this.currentCase.workbenches && this.currentCase.workbenches[0]) {
    //   const actualWbTabIndex = localStorage.getItem('actual-case-workbench')
    //   if (this.currentCase.workbenches[actualWbTabIndex]) {
    //     this.$router.push(`/cases/${this.$route.params._id}/${this.currentCase.workbenches[actualWbTabIndex]._id}`)
    //   } else {
    //     this.$router.push(`/cases/${this.$route.params._id}/${this.currentCase.workbenches[0]._id}`)
    //   }
    // }
  },
  async created () {
    window.history.replaceState(window.history.state, '', '/cases')
    await this.fetchCase(this.$route.params._id)
    // if (process.env.NODE_ENV === 'development') window.location.assign(`${'localhost:8080'}${this.$route.fullPath}`)
    // else window.location.assign(`${this.$route.fullPath}`)
    if (this.currentCase && this.currentCase.workbenches && this.currentCase.workbenches[0]) {
      const actualWbTabIndex = localStorage.getItem('actual-case-workbench')
      if (this.currentCase.workbenches[actualWbTabIndex] && !this.$route.params.workbenchId) {
        this.$router.push(`/cases/${this.$route.params._id}/${this.currentCase.workbenches[actualWbTabIndex]._id}`)
      } else {
        if (this.$route.params.workbenchId && this.$route.params.templateId) this.$router.push(`/cases/${this.$route.params._id}/${this.$route.params.workbenchId}/${this.$route.params.templateId}`).catch(() => {})
        else if (this.$route.params.workbenchId && !this.$route.params.templateId) this.$router.push(`/cases/${this.$route.params._id}/${this.$route.params.workbenchId }`).catch(() => {})
        else this.$router.push(`/cases/${this.$route.params._id}/${this.currentCase.workbenches[0]._id}`)
      }
    }
    EventBus.$on('update-component-key', this.onUpdateComponentKey)
    EventBus.$on('actual-comments-editor', this.onActualCommentsEditor)
    EventBus.$on('select-comment', this.onSelectCommentEvent)
    EventBus.$on('no-comment-selection', this.onNoCommentSelection)
    EventBus.$on('actual-tab-template-view-cases', this.onActualTabTemplateViewCases)
    EventBus.$on('refresh-view', this.refreshView)
    EventBus.$on('go-to-last-wb-tab', this.onGoToLastWbTab)
    this.$nextTick(() => {
      this.loading = false
    })
  },
  beforeDestroy () {
    EventBus.$off('update-component-key', this.onUpdateComponentKey)
    EventBus.$off('actual-comments-editor', this.onActualCommentsEditor)
    EventBus.$off('select-comment', this.onSelectCommentEvent)
    EventBus.$off('no-comment-selection', this.onNoCommentSelection)
    EventBus.$off('actual-tab-template-view-cases', this.onActualTabTemplateViewCases)
    EventBus.$off('refresh-view', this.refreshView)
    EventBus.$off('go-to-last-wb-tab', this.onGoToLastWbTab)
    this.openLeftDrawerMenu()
  },
  methods: {
    ...mapActions({
      fetchCase: 'cases/fetchCase',
      updateCase: 'cases/updateCase',
      updateCompanyCase: 'companyCases/updateCompanyCase',
      updateGroupCase: 'groupCases/updateGroupCase',
      updateCaseClient: 'cases/updateCaseClient',
      addCaseWorkbench: 'cases/addCaseWorkbench',
      addCompanyCaseWorkbench: 'companyCases/addCompanyCaseWorkbench',
      addGroupCaseWorkbench: 'groupCases/addGroupCaseWorkbench',
      updateCaseWorkbench: 'cases/updateCaseWorkbench',
      updateCompanyCaseWorkbench: 'companyCases/updateCompanyCaseWorkbench',
      updateGroupCaseWorkbench: 'groupCases/updateGroupCaseWorkbench',
      deleteCaseWorkbenchDocument: 'cases/deleteCaseWorkbenchDocument',
      deleteCompanyCaseWorkbenchDocument: 'companyCases/deleteCompanyCaseWorkbenchDocument',
      deleteGroupCaseWorkbenchDocument: 'groupCases/deleteGroupCaseWorkbenchDocument',
      deleteCaseWorkbench: 'cases/deleteCaseWorkbench',
      deleteCompanyCaseWorkbench: 'companyCases/deleteCompanyCaseWorkbench',
      deleteGroupCaseWorkbench: 'groupCases/deleteGroupCaseWorkbench',
      addCaseWorkbenchTemplate: 'cases/addCaseWorkbenchTemplate',
      deleteCaseWorkbenchTemplate: 'cases/deleteCaseWorkbenchTemplate',
      uploadDocument: 'documents/uploadDocument'
    }),
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    onGoToLastWbTab (_case) {
      setTimeout(() => {
        this.$router.push(`/cases/${this.$route.params._id}/${_case.workbenches[_case.workbenches.length - 1]._id}`)
        localStorage.setItem('actual-case-workbench', _case.workbenches.length - 1)
      }, '500')
    },
    setActualWorkbench (idx) {
      localStorage.setItem('actual-case-workbench', idx)
    },
    refreshView () {
      this.templateSuggestionsComponentKey++
    },
    onUpdateComponentKey () {
      this.componentKey++
    },
    openLeftDrawerMenu () {
      this.toggleDrawer(true)
    },
    closeLeftDrawerMenu () {
      this.toggleDrawer(false)
    },
    openShareCaseDialog () {
      this.$refs.ShareCase.dialog = true
    },
    onSearchInput (value) {
      this.searchInput = value
    },
    search (input) {
      this.searchText = input
    },
    resetRequiredDocDialog () {
      this.searchText = null
      this.activeReqDocuments = []
      this.customField = false
      this.customFieldInput = null
      this.toolbarActive = true
    },
    onActualTabTemplateViewCases (actualTab) {
      this.actualTabTemplateViewCases = actualTab
    },
    onAddNewDocument () {
      EventBus.$emit('add-new-file-input-suggestion')
    },
    onDragStartToWorkbench () {
      this.dragAreaDocsCase = true
      EventBus.$emit('drag-area-active')
    },
    onDragEndToWorkbench () {
      this.dragAreaDocsCase = false
      EventBus.$emit('drag-area-not-active')
    },
    async onChangeDocumentList (evnt) {
      try {
        await this.updateCase({
          _id: this.currentCase._id,
          payload: {
            payload: {
              movedCaseDocument: true,
              documentId: evnt.moved.element._id,
              newIndex: evnt.moved.newIndex,
              oldIndex: evnt.moved.oldIndex
            }
          }
        })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('error|moving_document_fail'),
          snackbarColor: 'error',
          color: 'white'
        })
      }
    },
    onDragStart () {
      this.dragAreaRequiredDocsCase = true
      EventBus.$emit('drag-area-required-docs-active')
    },
    onDragEnd () {
      this.dragAreaRequiredDocsCase = false
      EventBus.$emit('drag-area-required-docs-not-active')
    },
    async onDragChange (evnt) {
      try {
        await this.updateCase({
          _id: this.currentCase._id,
          payload: {
            payload: {
              requiredCaseDocumentsDrag: true,
              field: evnt.moved.element,
              newIndex: evnt.moved.newIndex,
              oldIndex: evnt.moved.oldIndex
            }
          }
        })
        this.addNewRequiredDocDialog = false
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|adding_required_document_failed'),
          snackbarColor: 'error',
          color: 'white'
        })
      }
    },
    checkIfSuggestionTypeAvailableInDocuments (suggestion) {
      if (this.actualWorkbench) {
        return this.actualWorkbench.assignedDocuments.find(doc => {
          return doc.info.document_type ? doc.info.document_type.toLowerCase() === suggestion.fieldKey.toLowerCase() : false
        })
      }
    },
    // verifyUserRole () {
    //   if (this.company && this.company.userRoles) {
    //     const regularUser = this.company.userRoles.basic.some(u => u === this.account._id)
    //     const advancedUser = this.company.userRoles.advanced.some(u => u === this.account._id)
    //     const adminUser = this.company.userRoles.admin.some(u => u === this.account._id)
    //     const adminOwnerUser = this.company.userRoles.adminOwner.some(u => u === this.account._id)
    //     if (regularUser) {
    //       this.userRole = 'regular'
    //       return this.userRole
    //     } else if (advancedUser) {
    //       this.userRole = 'advanced'
    //       return this.userRole
    //     } else if (adminUser) {
    //       this.userRole = 'admin'
    //       return this.userRole
    //     } else if (adminOwnerUser) {
    //       this.userRole = 'adminOwner'
    //       return this.userRole
    //     }
    //   } else {
    //     return 'singleAcc'
    //   }
    // },
    onSelectCommentEvent (label, id) {
      let newArray = []
      for (let i = 0; i < this.actualCommentsEditorStorage.length; i++) {
        const comment = this.actualCommentsEditorStorage[i]
        if (comment.attrs.id === id) {
          document.querySelectorAll('#commentText').forEach(ele => {
            newArray.push(ele)
          })
          let foundPosition = newArray.findIndex(ele => ele.innerText.indexOf(comment.attrs.commentText.trim()) !== -1)
          let elementToScroll = newArray[foundPosition]
          elementToScroll.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })

          // Move element to the left option
          elementToScroll.style.zIndex = 1
          elementToScroll.style.transform = `translate(-1.0rem, -0.1ex)`

          this.commentId = id
          this.elevationEffect = true
          // setTimeout(this.elevationEffectTimeOut, 2000)
        }
      }
    },
    onNoCommentSelection () {
      if (this.elevationEffect) {
        this.elevationEffect = false
        document.querySelectorAll('#commentText').forEach(ele => {
          // Move element back
          ele.style.zIndex = 0
          ele.style.transform = `translate(0rem, 0ex)`
        })
      }
    },
    elevationEffectTimeOut () {
      this.elevationEffect = false
      this.commentId = ''
    },
    async addWorkbench () {
      const res = await this.$dialog.prompt({
        title: this.$t('mixed|new_workbench'),
        text: this.$t('actions|enter_workbench_name'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text',
          outlined: true,
          dense: true,
          class: 'mt-6'
        },
        actions: [{
          text: this.$t('actions|submit'), color: 'primary'
        }]
      })
      if (!res) return
      try {
        const payload = {
          name: res
        }
        await this.addCaseWorkbench({ _id: this.currentCase._id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    changeRightPanelExpand (value) {
      this.rightPanelExpand = value
    },
    assignCaseDocument () {
      let forwardCompanyDocuments = []
      let forwardGroupDocuments = []
      let documents = []
      let _folders = this.folders
      let forwardCompanyFolders = []
      let forwardGroupFolders = []
      const role = this.verifyUserRole(this.company, this.account)
      // verifyUserRole => generalMixin
      if ((role && role !== 'regular') || !this.account.companyId) {
        documents = this.documents.filter((doc) => {
          const alreadyInUse = this.currentCase.documents.some(d => d._id === doc._id)
          return !alreadyInUse
        })
      }
      if (this.companyDocuments && this.companyDocuments.length) {
        forwardCompanyDocuments = this.companyDocuments.filter((doc) => {
          const alreadyInUse = this.currentCase.documents.some(d => d._id === doc._id)
          return !alreadyInUse
        })
      }
      if (this.filteredGroupDocuments && this.filteredGroupDocuments.length) {
        forwardGroupDocuments = this.filteredGroupDocuments.filter((doc) => {
          const alreadyInUse = this.currentCase.documents.some(d => d._id === doc._id)
          return !alreadyInUse
        })
      }
      EventBus.$emit('dialog-documents', {
        documents: documents,
        companyDocuments: forwardCompanyDocuments,
        groupDocuments: forwardGroupDocuments,
        fromCaseView: true,
        actualCase: this.currentCase,
        userRole: role,
        company: this.company,
        account: this.account,
        callback: async (docs) => {
          this.updateCase({
            _id: this.currentCase._id,
            payload: {
              payload: {
                documents: docs
              }
            }
          })
        },
        personalFolders: _folders,
        companyFolders: forwardCompanyFolders,
        groupFolders: forwardGroupFolders
      })
    },
    async removeCaseDocument (doc) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure') + ' ' + this.$t('warning|document_removed_from_all_workbenches'),
        title: this.$t('common|warning')
      })
      if (!res) return
      this.documentsPanelLoading = true
      try {
        await this.updateCase({
          _id: this.currentCase._id,
          payload: {
            payload: {
              removeDocument: doc._id
            }
          }
        })
      } finally {
        this.documentsPanelLoading = false
      }
    },
    async updateCaseName (currentCaseName) {
      this.updateCase({
        _id: this.currentCase._id,
        payload: {
          payload: { caseName: currentCaseName }
        }
      })
    },
    updateProgress (progressValue) {
      this.updateCase({
        _id: this.currentCase._id,
        payload: {
          payload: { progress: progressValue }
        }
      })
    },
    async updateWorkbenches (actualWbs) {
      for (let i = 0; i < actualWbs.length; i++) {
        const wb = actualWbs[i]
        await this.updateCaseWorkbench({
          _id: this.currentCase._id,
          workbenchId: wb._id,
          payload: {
            name: wb.name
          }
        })
        this.editWorkbenchesDialog = false
      }
    },
    async deleteWorkbench (workbench) {
      const res = await this.$dialog.confirm({
        text: `Are you sure you want to delete ${workbench.name} workbench?`,
        title: this.$t('common|warning')
      })
      if (!res) return
      this.loadingDeleteWorkbench = true
      await this.deleteCaseWorkbench({ _id: this.currentCase._id, workbenchId: workbench._id })
      setTimeout(() => {
        this.loadingDeleteWorkbench = false
      }, '1500')
    },
    async uploadDocumentAction (document) {
      const payload = {
        language: document.language,
        file: document.file,
        caseId: this.currentCase._id,
        clientId: this.currentCase.client._id
      }
      return this.uploadDocument(payload)
    },
    async updateQuestionnaire (questionairre) {
      const filesToUpload = questionairre.fields.filter((f) => {
        return f.fieldType === 'file-input'
      })
      const documents = []
      for (let i = 0; i < filesToUpload.length; i++) {
        const fileData = await this.uploadDocumentAction(filesToUpload[i].value[0])
        documents.push(fileData.data._id)
      }
      const payloadFields = questionairre.fields.filter((f) => {
        return f.fieldType !== 'file-input'
      })
      await this.$axios.post(`/updateQuestionnaire/${questionairre._id}`, {
        fields: payloadFields,
        documents
      })
      await this.fetchCase(this.currentCase._id)
    },
    async pinToDashboard (caseFile) {
      const payload = {
        payload: {
          setPin: this.account._id
        }
      }
      try {
        await this.updateCase({
          _id: caseFile._id,
          payload
        })
        this.addToast({
          title: this.$t('message|case_pin_to_dashboard_success'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('error|case_pin_to_dashboard_fail'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async removePinFromDashboard (caseFile) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning')
      })
      if (!res) return
      if (res) {
        const payload = {
          payload: {
            removePin: this.account._id
          }
        }
        try {
          await this.updateCase({
            _id: caseFile._id,
            payload
          })
          this.addToast({
            title: this.$t('message|case_removal_dashboard_success'),
            color: 'white',
            snackbarColor: 'succsess'
          })
        } catch (e) {
          this.addToast({
            title: this.$t('error|case_removal_dashboard_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    openInfoProgressDialog () {
      this.$refs.InfoProgressDialog.dialog = true
    },
    inUseRequiredDocumentsInQuestionnaire (suggestion) {
      const inUse = this.actualTemplate.questionnaire.fields.find(f => f.fieldKey.toLowerCase() === suggestion.fieldKey.toLowerCase())
      if (inUse) {
        return true
      } else {
        return false
      }
    },
    inUseInQuestionnaire (suggestion) {
      const inUse = this.actualTemplate.questionnaire.fields.find(f => f.fieldKey.toLowerCase() === suggestion.attrs.fieldKey.toLowerCase())
      if (inUse) {
        return true
      } else {
        return false
      }
    },
    inUseInTemplate (questionnaireField) {
      const inUse = this.actualSuggestionsEditorStorage.find(f => f.attrs.fieldKey.toLowerCase() === questionnaireField.fieldKey.toLowerCase())
      if (inUse) {
        return true
      } else {
        return false
      }
    },
    scrollToSuggestion (suggestion) {
      const element = document.getElementById(suggestion.id ? suggestion.id : suggestion.attrs.id)
      if (element && (element.className === 'mention' || element.className === 'mention is-full' || element.className === 'mention view-state is-full')) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
        element.style.backgroundColor = '#D50000'
        setTimeout(() => {
          element.style.backgroundColor = ''
        }, '1000')
      } else if (element && element.className !== 'mention') {
        this.addToast({
          title: this.$t('error|suggestion_not_in_use'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    scrollToQuestionnaireField (suggestion) {
      if (suggestion && suggestion.fieldType && suggestion.fieldType === 'file-input') {
        let transformedSuggestion = {}
        transformedSuggestion.attrs = suggestion
        const usedInQuestionnaire = this.inUseInQuestionnaire(transformedSuggestion)
        const scrollToElement = {
          id: suggestion.id,
          inUse: usedInQuestionnaire,
          tab: 1 // questionnaire
        }
        EventBus.$emit('scroll-to-field-in-questionnaire', scrollToElement)
      }
    },
    showStatus (questionnaireField) {
      const scrollToElement = {
        id: questionnaireField.id,
        inUse: true,
        fromSuggestionPanel: true,
        tab: 1 // questionnaire
      }
      EventBus.$emit('scroll-to-field-in-questionnaire', scrollToElement)
      // if (questionnaireField.value) {
      //   this.addToast({
      //     title: this.$t('questionnaire|client_answer_available'),
      //     color: 'white',
      //     snackbarColor: 'success'
      //   })
      // } else {
      //   this.addToast({
      //     title: this.$t('questionnaire|no_answer_available'),
      //     color: 'white',
      //     snackbarColor: 'error'
      //   })
      // }
    },
    extractedValues (suggestion) {
      const arr = []
      this.actualWorkbench.assignedDocuments.forEach((doc) => {
        if (doc.info && doc.info.content && Array.isArray(doc.info.content)) {
          doc.info.content.forEach(content => {
            if (content.lavvira_labels) {
              Object.keys(content.lavvira_labels).forEach((key) => {
                if (suggestion.attrs.fieldKey.toLowerCase() === key.toLowerCase()) {
                  content.lavvira_labels[key].forEach((val) => {
                    arr.push({
                      value: val,
                      doc
                    })
                  })
                }
              })
            }
          })
        }
      })
      if (arr.length) {
        return true
      } else {
        return false
      }
    },
    async generateCaseModel () {
      const res = await this.$dialog.warning({
        title: this.$t('warning|new_case_model_from_case'),
        text: this.$t('warning|documents_exclusion_if_not_marked'),
        showClose: false,
        width: '750'
      })
      if (!res) return
      this.caseModelData = {
        caseModelName: this.currentCase.caseName,
        description: '',
        category: 'none',
        caseId: this.currentCase._id,
        _case: this.currentCase
      }
      this.$refs.AddCaseModel.addCaseModelDialog = true
    },
    openCaseTimeline () {
      this.$refs.CaseTimeline.caseTimeLineDialog = true
    },
    triggerAction (actionType) {
      if (actionType === 'addWorkbench') {
        this.addWorkbench()
      }
      if (actionType === 'editWorkbenchesDialog') {
        this.editWorkbenchesDialog = true
      }
    },
    getTheRightIcon (actualStatus) {
      switch (actualStatus) {
      case 'To do':
        return this.caseStatuses.todo.icon
      case 'In progress':
        return this.caseStatuses.inprogress.icon
      case 'Done':
        return this.caseStatuses.done.icon
      default:
        return 'mdi-progress-pencil'
      }
    },
    getTranslatedStatus (text) {
      if (text === 'To do') return this.$t('cases|to_do_cases')
      if (text === 'In progress') return this.$t('cases|in_progress')
      if (text === 'Done') return this.$t('cases|finished_cases')
    },
    async exportCase (currentCase) {
      this.loading = true
      await this.createZip()
      this.loading = false
    },
    async downloadDocument (path) {
      const { data } = await this.$axios({
        url: path,
        method: 'GET',
        responseType: 'blob' // important
      })
      return data
    },
    async createZip () {
      let zipNameInput = this.currentCase.caseName
      let zip = new JSZip()
      // let workbenches = zip.folder(`workbenches`)
      let docs = zip.folder(`${this.currentCase.caseName} documents`)
      for (let i = 0; i < this.currentCase.documents.length; i++) {
        // we need to remove .files/ from path. .files is available in backend
        const slicedPath = this.currentCase.documents[i].raw.path.slice(6, this.currentCase.documents[i].raw.path.length)
        const path = `/downloadDocument/${slicedPath}`
        const file = await this.downloadDocument(path)
        docs.file(this.currentCase.documents[i].raw.originalname, new Blob([file]))
      }
      for (let index = 0; index < this.currentCase.workbenches.length; index++) {
        const wb = this.currentCase.workbenches[index]
        let workbenchFolder = zip.folder(`${wb.name}`)
        for (let idx = 0; idx < wb.assignedDocuments.length; idx++) {
          const doc = wb.assignedDocuments[idx]
          const slicedPath = doc.raw.path.slice(6, doc.raw.path.length)
          const path = `/downloadDocument/${slicedPath}`
          const wbFile = await this.downloadDocument(path)
          workbenchFolder.file(doc.raw.originalname, new Blob([wbFile]))
        }
        for (let i = 0; i < wb.templates.length; i++) {
          const template = wb.templates[i]
          const json = template.templateData.json
          this.$refs.Editor.editor.commands.setContent(json)
          const doc = this.$refs.Editor.editor.view.state.doc
          const type = 'docx'
          const templateName = template.name + '.docx'
          const caseExport = true
          const exported = await exportTemplate(doc, templateName, type, caseExport)
          workbenchFolder.file(template.name, exported)
        }
      }
      this.$refs.Editor.editor.destroy()
      zip.generateAsync({ type: 'blob' })
        .then(function (content) {
          // see FileSaver.js
          saveAs(content, zipNameInput)
        }).catch((err) => {
          console.error('createZip', err)
        })
    },
    assignNewClient () {
      this.$refs.NewCaseClientDialog.dialog = true
    },
    async addNewClient (newClient) {
      let clientName
      if (newClient.clientType === 'individual') {
        clientName = newClient.clientData.given_names + ' ' + newClient.clientData.surname
      }
      if (newClient.clientType === 'company') {
        clientName = newClient.clientData.company_name
      }
      const _id = this.currentCase._id
      const payload = {
        newClientId: newClient._id,
        clientName: clientName
      }
      try {
        await this.updateCaseClient({ _id, payload })
      } catch (error) {
        console.error(error)
      }
    },
    assignRequiredCaseDocument () {
      this.addNewRequiredDocDialog = true
    },
    async addCustomField (customFieldInput) {
      const field = {
        id: uuidv4(),
        name: customFieldInput,
        fieldType: 'file-input',
        fieldKey: customFieldInput.split(' ').join('_'),
        key: customFieldInput.split(' ').join('_')
      }
      try {
        await this.updateCase({
          _id: this.currentCase._id,
          payload: {
            payload: {
              requiredCaseDocuments: [field],
              addCaseRequiredDocuments: true
            }
          }
        })
        this.addNewRequiredDocDialog = false
        this.resetRequiredDocDialog()
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|adding_required_document_failed'),
          snackbarColor: 'error',
          color: 'white'
        })
      }
    },
    async addDocumentSuggestionsToCase (fields) {
      try {
        await this.updateCase({
          _id: this.currentCase._id,
          payload: {
            payload: {
              requiredCaseDocuments: fields,
              addCaseRequiredDocuments: true
            }
          }
        })
        this.addNewRequiredDocDialog = false
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|adding_required_document_failed'),
          snackbarColor: 'error',
          color: 'white'
        })
      }
    },
    async removeRequiredDocument (suggestion) {
      let templateNames = []
      let templateArr = []
      let joinedName
      let res
      for (let i = 0; i < this.currentCase.workbenches.length; i++) {
        this.currentCase.workbenches[i].templates.forEach(temp => {
          const found = temp.questionnaire.fields.find(f => f.id === suggestion.id)
          if (found) {
            templateNames.push(temp.name)
            templateArr.push(temp)
          }
        })
      }
      if (templateNames.length) {
        joinedName = templateNames.join(', ')
      }
      if (joinedName && joinedName.length) {
        res = await this.$dialog.confirm({
          type: 'warning',
          text: this.$t('common|are_you_sure') + ' ' + this.$t('fields|field_will_be_also_deleted') + ' ' + `${joinedName}` + ' ' + this.$t('questionnaire|questionnaire').toLowerCase(),
          title: this.$t('common|warning')
        })
        if (!res) return
        for (let i = 0; i < templateArr.length; i++) {
          const template = templateArr[i]
          try {
            const payload = {
              fieldToBeDeleted: suggestion,
              deleteFieldFromQuestionnaire: true,
              requiredCaseDocumentToBeRemoved: suggestion,
              removeCaseRequiredDocument: true
            }
            const _id = template.questionnaire._id
            const caseId = this.currentCase._id
            const workbench = this.currentCase.workbenches.find(w => {
              const foundTemp = w.templates.find(temp => temp._id === template._id)
              if (foundTemp) return w
            })
            const workbenchId = workbench._id || undefined
            const templateId = template._id
            await this.updateQuestionnaireInCase({ _id, caseId, workbenchId, templateId, payload })
          } catch (e) {
            console.error(e, 'error')
            this.addToast({
              title: this.$t('warning|questionnaire_update_failed'),
              color: 'white',
              snackbarColor: 'error'
            })
          }
        }
      } else {
        res = await this.$dialog.confirm({
          text: this.$t('common|are_you_sure'),
          title: this.$t('common|warning')
        })
        if (!res) return
        try {
          await this.updateCase({
            _id: this.currentCase._id,
            payload: {
              payload: {
                requiredCaseDocumentToBeRemoved: suggestion,
                removeCaseRequiredDocument: true
              }
            }
          })
        } catch (e) {
          console.error(e, 'error')
          this.addToast({
            title: this.$t('warning|workbench_update_failed'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .ghost {
    background-color: #d7d7d7;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
  }
  .chip-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center
  }
  .input:focus {
    outline: none !important;
    border:1px solid #234e79;
  }
  .workbenches-panel {
    &.is-expanded {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      z-index: 15;
    }
  }
  .centered-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .icon-position {
    position: relative;
  }
  .pin-position {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
  }
  .status-position {
    position: absolute;
    right: 3rem;
    top: 1.5rem;
  }
  .active {
    background-color: #eee;
  }
  .tabs-text {
    text-transform:none !important;
  }
  .fade-enter-active,
  .fade-leave-active {  transition: opacity 0.5s ease;}
  .fade-enter-from,.fade-leave-to {  opacity: 0;}
</style>
