<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('mixed|assign_new_client') }}
      </v-card-title>

      <v-card-text v-if="!selectedClient">
        <v-menu
          :close-on-content-click="true"
          :nudge-width="200"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              outlined
              small
              v-bind="attrs"
              v-on="on"
            >
              {{ clientSections[selectedClientSection] }}
              <v-icon
                small
                right
              >
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group
              v-model="selectedClientSection"
              color="primary"
            >
              <v-list-item
                v-for="(item, index) in clientSections"
                :key="index"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-card-text>

      <v-divider
        v-if="!selectedClient"
      />

      <v-expand-transition>
        <v-card
          v-show="!selectedClient"
          flat
          class="scroll"
          height="300"
          width="650"
        >
          <v-list
            v-model="selectedClient"
          >
            <v-list-item-group>
              <template v-for="client in filteredClients">
                <v-list-item
                  :key="client._id"
                  :value="client"
                  active-class="blue--text text--accent-4"
                  @click="selectedClient = client"
                >
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="client.clientType === 'individual'"
                      >
                        <v-icon
                          class="mb-1"
                        >
                          mdi-account
                        </v-icon>
                        {{ client.clientData.given_names && client.clientData.surname ? client.clientData.given_names + ' ' + client.clientData.surname : client.clientData.given_names ? client.clientData.given_names : '' }}
                      </v-list-item-title>
                      <v-list-item-title
                        v-if="client.clientType === 'company'"
                      >
                        <v-icon
                          class="mb-1"
                        >
                          mdi-account
                        </v-icon>
                        {{ client.clientData.company_name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon
                        color="primary accent-4"
                      >
                        mdi-chevron-right
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-expand-transition>
      <v-expand-transition>
        <v-card
          v-if="selectedClient"
          v-show="selectedClient"
          flat
          class="scroll pa-3"
          min-height="260"
          width="650"
        >
          <v-card-text>
            <ClientCardMini
              :key="selectedClient._id"
              :client="selectedClient"
              :disabled="true"
              class="mini-card"
            />
          </v-card-text>
          <v-card-actions
            class="mt-4"
          >
            <v-btn
              small
              text
              @click="selectedClient = null"
            >
              <v-icon
                class="mr-1"
              >
                mdi-undo
              </v-icon>
              back
            </v-btn>
            <v-spacer />
            <v-btn
              small
              rounded
              color="primary"
              min-width="120"
              @click="$emit('add-new-client', selectedClient); reset()"
            >
              use
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import generalMixin from '@/utils/generalMixin.js'
import ClientCardMini from '@/pages/Clients/ClientCardMini.vue'

export default {
  components: {
    ClientCardMini
  },
  mixins: [generalMixin],
  data: () => ({
    dialog: false,
    selectedClientSection: 0,
    selectedClient: null
  }),
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      clients: state => state.clients.clients,
      companyClients: state => state.companyClients.companyClients,
      groupClients: state => state.groupClients.groupClients
    }),
    clientSections () {
      let myAvailableSections = []
      if (this.account && !this.account.companyId) {
        myAvailableSections.push(this.$t('common|my') + ' ' + this.$t('clients|clients'))
      }
      if (this.account && this.account.companyId && this.company) {
        const userRole = this.verifyUserRole(this.company, this.account)
        // verifyUserRole could be found in generalMixin
        if (userRole === 'regular') {
          myAvailableSections.push(this.company.companyData.companyName)
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push(group.groupName)
            }
          }
        } else {
          myAvailableSections.push(this.$t('common|my') + ' ' + this.$t('clients|clients'))
          myAvailableSections.push(this.company.companyData.companyName)
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push(group.groupName)
            }
          }
        }
      }
      return myAvailableSections
    },
    filteredClients () {
      let clients
      if (this.clientSections[this.selectedClientSection] === this.$t('common|my') + ' ' + this.$t('clients|clients')) {
        clients = this.clients
      }
      if (this.company && this.clientSections[this.selectedClientSection] === this.company.companyData.companyName) {
        clients = this.companyClients
      }
      if (this.company && this.clientSections[this.selectedClientSection] !== this.$t('common|my') + ' ' + this.$t('clients|clients') && this.clientSections[this.selectedClientSection] !== this.company.companyData.companyName) {
        let clients = []
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          if (group.groupName === this.clientSections[this.selectedClientSection]) {
            this.groupClients.forEach(cl => {
              const sharedDoc = cl.sharedWith.groups.find(g => g._id ? g._id : g === group._id)
              if (sharedDoc) {
                clients.push(cl)
              }
            })
          }
        }
        return clients
      }
      return clients
    }
  },
  methods: {
    reset () {
      this.dialog = false
      this.selectedClientSection = 0
      this.selectedClient = null
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll {
  overflow-y: auto;
}
.mini-card {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  width: 40%;
}
</style>
