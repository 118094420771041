<template>
  <v-dialog
    v-if="addCaseModelDialog"
    v-model="addCaseModelDialog"
    persistent
    keydown
    max-width="800px"
    transition="dialog-bottom-transition"
  >
    <v-container
      style="background-color: white;"
    >
      <v-card
        flat
      >
        <v-toolbar
          flat
          width="100%"
          :rounded="false"
          class="mt-n3"
        >
          <v-toolbar-title>
            {{ $t('actions|add_new_case_model') }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <div
              style="position: absolute; top: 3px; right: 7px"
            >
              <v-btn
                icon
                @click="close()"
                @mouseover="hoverCloseButtonIcon = true"
                @mouseleave="hoverCloseButtonIcon = false"
              >
                <v-icon
                  :small="hoverCloseButtonIcon"
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </v-toolbar-items>
        </v-toolbar>

        <div class="text-center">
          <v-progress-circular
            v-show="isLoading"
            color="warning"
            width="4"
            indeterminate
            size="40"
          />
        </div>

        <v-card-text>
          <div>
            <v-stepper
              v-model="e6"
              vertical
              flat
            >
              <v-stepper-step
                :complete="e6 > 1"
                step="1"
              >
                {{ $t('actions|add_to') }}
                <small
                  v-if="selectedSection"
                  class="mt-1"
                >
                  {{ selectedSection }}
                </small>
              </v-stepper-step>

              <v-stepper-content
                step="1"
              >
                <v-select
                  ref="selectedSectionField"
                  v-model="selectedSection"
                  :items="availableSections"
                  :rules="[rules.required]"
                  item-text="text"
                  item-value="text"
                  item-disabled="disabled"
                  outlined
                  dense
                  :disabled="disabledField"
                  :label="$t('case_models|case_model_is_provided_for')"
                  class="mx-3 my-2"
                  @input="e6++"
                />
              </v-stepper-content>

              <v-stepper-step
                :complete="e6 > 2"
                :step="2"
              >
                {{ $t('actions|enter_name_and_description') }}
                <small
                  v-if="name"
                  class="mt-1"
                >
                  {{ $t('case_models|case_model_name') }}: {{ name }}
                </small>
                <small
                  v-if="description"
                  class="mt-1"
                >
                  {{ $t('common|description') }}: {{ description }}
                </small>
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-text-field
                  ref="caseModelNameField"
                  v-model="name"
                  outlined
                  :label="this.$t('case_models|case_model_name')"
                  :rules="[rules.required]"
                  dense
                  class="mx-3 my-2"
                />
                <v-text-field
                  v-model="description"
                  outlined
                  dense
                  :label="$t('common|description')"
                  class="mx-3"
                />
                <div class="d-flex pa-2">
                  <v-spacer />
                  <v-btn
                    v-if="e6 === 2"
                    color="primary"
                    x-small
                    rounded
                    min-width="100"
                    class="mr-1"
                    :disabled="disabledRule"
                    @click="e6++"
                  >
                    next
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-step
                :complete="!!selectedCategory.length"
                :step="3"
              >
                {{ $t('actions|select_category') }}
                <small
                  v-if="name"
                  class="mt-1"
                >
                  {{ selectedCategory }}
                </small>
              </v-stepper-step>

              <v-stepper-content step="3">
                <v-select
                  v-model="selectedCategory"
                  outlined
                  dense
                  :label="$t('actions|choose_category')"
                  :items="availableCategories"
                  item-text="value"
                  item-value="value"
                  class="mx-3 my-2"
                >
                  <template
                    slot="item"
                    slot-scope="data"
                  >
                    <v-list
                      v-model="selectedCategory"
                    >
                      <v-list-item
                        three-line
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon
                              x-small
                            >
                              mdi-tag-outline
                            </v-icon>
                            {{ data.item.value }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{ $t('mixed|category_created_by') }}:</v-list-item-subtitle>
                          <v-list-item-subtitle>{{ data.item.createdBy }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                </v-select>
                <div class="d-flex pa-2">
                  <v-btn
                    color="primary"
                    outlined
                    x-small
                    rounded
                    min-width="100"
                    class="mr-1"
                    @click="e6--"
                  >
                    <v-icon
                      x-small
                      class="mr-2"
                    >
                      mdi-undo
                    </v-icon>
                    back
                  </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="e6 === 3"
            color="primary"
            small
            rounded
            min-width="150"
            :loading="isLoading"
            :disabled="disabledRule"
            @click="createCaseModel()"
          >
            {{ $t('actions|submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { userHasAccess } from '@/utils/utils'
import { EventBus } from '@/utils/EventBus'
import { Editor } from '@tiptap/vue-2'
import { Color } from '@/tiptap/extensions/colorStyle'
import { DropCap } from '@/pages/TemplateEditor/DropCap/dropCap'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import StarterKit from '@tiptap/starter-kit'
import HashTag from '@/pages/TemplateEditor/HashTag/HashTag'
import Comment from '@/pages/TemplateEditor/Comment/Comment'
import FontSize from '@/pages/TemplateEditor/FontSize/FontSize'
import CustomTable from '@/pages/TemplateEditor/CustomTable/CustomTable.js'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import Heading from '@tiptap/extension-heading'
import Typography from '@tiptap/extension-typography'
import Paragraph from '@tiptap/extension-paragraph'
import Underline from '@tiptap/extension-underline'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Superscript from '@tiptap/extension-superscript'
import Subscript from '@tiptap/extension-subscript'
import CharacterCount from '@tiptap/extension-character-count'
import generalMixin from '@/utils/generalMixin.js'

export default {
  mixins: [generalMixin],
  props: {
    caseModelData: {
      type: Object,
      default: null
    },
    fromCase: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      name: '',
      description: '',
      selectedCategory: '',
      addCaseModelDialog: false,
      isLoading: false,
      hoverCloseButtonIcon: false,
      actualTab: null,
      selectedSection: null,
      disabledField: false,
      rules: {
        required: value => !!value || this.$t('common|field_required')
      },
      editor: null,
      e6: 1
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      caseForms: state => state.caseForms.caseForms,
      settings: state => state.settings.settings
    }),
    disabledRule () {
      let rule
      if (!this.name.length) {
        rule = true
      } else if (!this.selectedSection) {
        rule = true
      } else {
        rule = false
      }
      return rule
    },
    availableCategories () {
      let categories = []
      if (this.account && this.actualTab && (this.actualTab.isMyTab || this.selectedSection === this.$t('common|my') + ' ' + this.$t('case_models|case_models'))) {
        this.account.myCaseModelsCategories.forEach(cat => {
          const newCat = {
            createdBy: this.account.accountName ? this.account.accountName : this.$t('mixed|account_no_longer_available'),
            value: cat.value
          }
          categories.push(newCat)
        })
      } else if ((this.actualTab && this.actualTab.company) || (this.company && this.selectedSection && this.selectedSection.includes(this.company.companyData.companyName))) {
        this.company.companyCaseModelsCategories.forEach(cat => {
          const newCat = {
            createdBy: this.company.companyData.companyName,
            value: cat.value
          }
          categories.push(newCat)
        })
      } else if (this.actualTab && this.actualTab.group) {
        this.actualTab.group.groupCaseModelsCategories.forEach(cat => {
          const newCat = {
            createdBy: this.actualTab.group.groupName,
            value: cat.value
          }
          categories.push(newCat)
        })
      } else if (this.selectedSection && this.company && !this.selectedSection.includes(this.company.companyData.companyName) && this.selectedSection !== 'My templates') {
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          if (this.selectedSection.includes(group.groupName)) {
            group.groupCaseModelsCategories.forEach(cat => {
              const newCat = {
                createdBy: group.groupName,
                value: cat.value
              }
              categories.push(newCat)
            })
          }
        }
      } else if (this.actualTab && this.actualTab.isLavviraTab) {
        const filteredCategories = this.settings.lavviraSettings.lavviraCaseModelsCategories.map((cat) => {
          return {
            value: cat.value,
            createdBy: 'Lavvira'
          }
        })
        categories = filteredCategories
      }
      return categories
    },
    availableSections () {
      let myAvailableSections = []
      const getAccess = (_acc, _comp, _group, _tab, _from) => {
        return userHasAccess(_acc, _comp, _group, _tab, _from)
      }
      const accountAccess = getAccess(this.account, null, null, null, 'onlyAccountAccess')
      const companyAccess = getAccess(this.account, this.company, null, this.actualTab, 'availableSections')
      if (this.actualTab && this.actualTab.isLavviraTab) {
        myAvailableSections.push('Lavvira Case Models')
      } else {
        if (this.account && !this.account.companyId) {
          myAvailableSections.push({
            text: this.$t('common|my') + ' ' + this.$t('case_models|case_models'),
            disabled: !accountAccess
          })
        }
        if (this.account && this.account.companyId && this.company) {
          const userRole = this.verifyUserRole(this.company, this.account)
          // verifyUserRole could be found in generalMixin
          if (userRole === 'regular') {
            myAvailableSections.push(this.company.companyData.companyName + ' ' + this.$t('case_models|case_models'))
            for (let i = 0; i < this.company.groups.length; i++) {
              const group = this.company.groups[i]
              const participation = group.groupMembers.find(m => m._id === this.account._id)
              if (participation) {
                myAvailableSections.push({
                  text: group.groupName + ' ' + this.$t('case_models|case_models'),
                  disabled: !companyAccess
                })
              }
            }
          } else {
            myAvailableSections.push({
              text: this.$t('common|my') + ' ' + this.$t('case_models|case_models'),
              disabled: !accountAccess
            })
            myAvailableSections.push({
              text: this.company.companyData.companyName + ' ' + this.$t('case_models|case_models'),
              disabled: !companyAccess
            })
            for (let i = 0; i < this.company.groups.length; i++) {
              const group = this.company.groups[i]
              const participation = group.groupMembers.find(m => m._id === this.account._id)
              if (participation) {
                myAvailableSections.push({
                  text: group.groupName + ' ' + this.$t('case_models|case_models'),
                  disabled: !companyAccess
                })
              }
            }
          }
        }
      }
      return myAvailableSections
    }
  },
  watch: {
    addCaseModelDialog (val) {
      if (!val) this.close()
    }
  },
  created () {
    EventBus.$on('open-new-case-model-dialog', this.onOpenNewCaseModelDialog)
  },
  beforeDestroy () {
    EventBus.$off('open-new-case-model-dialog', this.onOpenNewCaseModelDialog)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      addCaseForm: 'caseForms/addCaseForm'
    }),
    async createCaseModel () {
      this.isLoading = true
      let payload = {}
      if (this.fromCase && this.caseModelData._case) {
        await this.makeEditorSetup()
        for (let i = 0; i < this.caseModelData._case.workbenches.length; i++) {
          const wb = this.caseModelData._case.workbenches[i]
          for (let index = 0; index < wb.templates.length; index++) {
            const temp = wb.templates[index]
            const content = temp.templateData.json
            await this.editor.commands.setContent(content)
            await this.editor.view.state.doc.descendants((node, pos) => {
              if (node.type.name === 'mention' && node.attrs.fieldValue) {
                this.editor.commands.setNodeSelection(pos)
                this.editor.commands.updateAttributes('mention', { fieldValue: null })
              }
            })
            const editorJSON = await this.editor.getJSON()
            this.caseModelData._case.workbenches[i].templates[index].templateData.json = editorJSON
          }
        }
        this.editor.destroy()
      }
      if (this.actualTab && this.actualTab.isLavviraTab) {
        payload = {
          name: this.name,
          description: this.description,
          category: this.selectedCategory,
          fromCase: this.fromCase,
          caseModelData: this.caseModelData ? this.caseModelData : null
        }
      } else if (this.selectedSection === this.$t('common|my') + ' ' + this.$t('case_models|case_models')) {
        payload = {
          name: this.name,
          description: this.description,
          category: this.selectedCategory,
          fromCase: this.fromCase,
          caseModelData: this.caseModelData ? this.caseModelData : null
        }
      } else if (this.selectedSection === this.company.companyData.companyName + ' ' + this.$t('case_models|case_models')) {
        payload = {
          name: this.name,
          description: this.description,
          category: this.selectedCategory,
          companyId: this.company._id,
          fromCase: this.fromCase,
          caseModelData: this.caseModelData ? this.caseModelData : null,
          caseId: this.caseModelData ? this.caseModelData.caseId : null
        }
      } else {
        let selectedGroup
        this.company.groups.forEach(group => {
          if (this.selectedSection.includes(group.groupName)) {
            selectedGroup = group
          }
        })
        payload = {
          name: this.name,
          description: this.description,
          category: this.selectedCategory,
          groupId: selectedGroup._id,
          fromCase: this.fromCase,
          caseModelData: this.caseModelData ? this.caseModelData : null
        }
      }
      try {
        await this.addCaseForm(payload)
        this.close()
      } catch (e) {
        console.error(e, 'error')
        this.isLoading = false
      } finally {
        this.isLoading = false
        this.close()
        this.addToast({
          title: this.$t('case_models|successfully_created'),
          color: 'white',
          snackbarColor: 'success'
        })
      }
    },
    onOpenNewCaseModelDialog (tab) {
      if (tab && Object.keys(tab).length) {
        this.actualTab = tab
        if (tab.isLavviraTab) this.selectedSection = 'Lavvira Case Models'
        if (tab.isMyTab) {
          this.selectedSection = this.$t('common|my') + ' ' + this.$t('case_models|case_models')
          this.disabledField = true
        }
        if (tab.isCompanyTab) {
          this.selectedSection = this.company.companyData.companyName + ' ' + this.$t('case_models|case_models')
          this.e6++
        }
        if (tab.isGroupTab) {
          this.selectedSection = tab.group.groupName + ' ' + this.$t('case_models|case_models')
          this.e6++
        }
      }
      this.addCaseModelDialog = true
    },
    async makeEditorSetup () {
      this.editor = new Editor({
        extensions: [
          Document,
          Text,
          Bold,
          Italic,
          Superscript,
          Subscript,
          CharacterCount.configure({
            mode: 'nodeSize'
          }),
          StarterKit.configure({
            // history should be switched off because of ydoc (ydoc has own history)
            // the other moduls are imported separetly because of configuration and bugs
            history: false,
            paragraph: false,
            heading: false,
            bold: false,
            document: false,
            italic: false,
            text: false
          }),
          Heading.configure({
            levels: [1, 2, 3, 4, 5, 6]
          }),
          TextAlign.configure({
            types: ['heading', 'paragraph'],
            alignments: ['left', 'center', 'right', 'justify']
          }),
          TextStyle,
          FontFamily,
          FontSize,
          Color,
          DropCap,
          Typography,
          CustomTable,
          TableRow,
          TableCell.extend({
            addAttributes () {
              return {
                ...this.parent?.(),
                backgroundColor: {
                  default: '#FFFFFF',
                  // Customize the HTML parsing (for example, to load the initial content)
                  parseHTML: element => element.getAttribute('table-background-color'),
                  // … and customize the HTML rendering.
                  renderHTML: attributes => {
                    return {
                      'table-background-color': attributes.backgroundColor,
                      style: `background-color: ${attributes.backgroundColor}`
                    }
                  }
                },
                border: {
                  default: '2px solid #ced4da;',
                  // Customize the HTML parsing (for example, to load the initial content)
                  parseHTML: element => element.getAttribute('border'),
                  // … and customize the HTML rendering.
                  renderHTML: attributes => {
                    return {
                      'border': attributes.border,
                      style: `border: ${attributes.border}`
                    }
                  }
                },
                colwidth: {
                  default: null,
                  parseHTML: (element) => {
                    const colwidth = element.getAttribute('colwidth')
                    const value = colwidth ? [parseInt(colwidth, 10)] : null
                    return value
                  },
                  renderHTML: (attributes) => {
                    return {
                      colwidth: attributes.colwidth,
                      style: attributes.colwidth
                        ? `width: ${attributes.colwidth}px`
                        : null
                    }
                  }
                }
              }
            }
          }),
          TableHeader.extend({
            addAttributes () {
              return {
                ...this.parent?.(),
                colwidth: {
                  default: null,
                  parseHTML: (element) => {
                    const colwidth = element.getAttribute('colwidth')
                    const value = colwidth ? [parseInt(colwidth, 10)] : null
                    return value
                  },
                  renderHTML: (attributes) => {
                    return {
                      colwidth: attributes.colwidth,
                      style: attributes.colwidth
                        ? `width: ${attributes.colwidth}px`
                        : null
                    }
                  }
                }
              }
            }
          }),
          Paragraph.extend({
            addAttributes () {
              return {
                color: {
                  default: null,
                  // Take the attribute values
                  renderHTML: attributes => {
                  // … and return an object with HTML attributes.
                    return {
                      style: `color: ${attributes.color}`
                    }
                  }
                },
                lineHeight: {
                  default: '1.2',
                  renderHTML: attributes => {
                  // … and return an object with HTML attributes.
                    return {
                      style: `line-height: ${attributes.lineHeight}`
                    }
                  }
                }
              }
            }
          }),
          Underline.configure({
            HTMLAttributes: {
              class: 'underline'
            }
          }),
          Comment,
          HashTag
        ]
      })
    },
    close () {
      this.addCaseModelDialog = false
      this.e6 = 1
      this.name = ''
      this.description = ''
      this.selectedCategory = ''
      this.selectedSection = null
      this.actualTab = null
      this.disabledField = false
      this.$refs.selectedSectionField.resetValidation()
      this.$refs.caseModelNameField.resetValidation()
    }
  }
}
</script>

<style lang="scss" scoped>
.general-form {
  position: relative;
  display: flex;
  flex-direction: column;
}
.general-form-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 50;
}
</style>
