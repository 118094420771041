<template>
  <v-dialog
    v-model="dialog"
    keydown
    content-class="vdialognew"
    max-width="700px"
    transition="dialog-bottom-transition"
    @keydown.esc="close()"
    @click:outside="close()"
  >
    <div v-if="dialog">
      <v-expand-transition>
        <v-card
          v-show="!infoCard"
          max-width="680px"
        >
          <v-toolbar
            flat
          >
            <v-toolbar-title>
              {{ $t('cases|share') }}
            <!-- <v-icon
              color="primary"
            >
              mdi-briefcase-edit
            </v-icon>
            <span class="primary--text ml-1">{{ currentCase.caseName }}</span> {{ $t('common|with') }}: -->
            </v-toolbar-title>
            <v-toolbar-items>
              <v-btn
                icon
                style="position: absolute; right: 2px; top: 0px;"
                class="mt-n2"
                @click="close()"
                @mouseover="hoverCloseButtonIcon = true"
                @mouseleave="hoverCloseButtonIcon = false"
              >
                <v-icon
                  v-if="hoverCloseButtonIcon"
                  small
                >
                  mdi-close
                </v-icon>
                <v-icon
                  v-else
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-subtitle class="black--text">
            {{ $t('clients|client') }}:
          </v-card-subtitle>
          <v-row
            v-if="currentCase.client && currentCase.client.realClient"
            align="start"
            class="mt-n2 ml-5"
          >
            <v-checkbox
              v-if="sharedWithClient && currentCase.client && currentCase.client.clientType === 'company'"
              v-model="sharedWithClient"
              disabled
              :label="`${currentCase.client.clientData.company_name}`"
              class="mt-n1"
            />
            <v-checkbox
              v-if="sharedWithClient && currentCase.client && currentCase.client.clientType === 'individual'"
              v-model="sharedWithClient"
              disabled
              :label="`${currentCase.client.clientData.given_names} ${currentCase.client.clientData.surname}`"
              class="mt-n1"
            />
            <v-checkbox
              v-if="!sharedWithClient && currentCase.client && currentCase.client.clientType === 'company'"
              v-model="shareWithClientIfNotShared"
              color="primary"
              class="mt-n1"
            >
              <template v-slot:label>
                <div
                  :class="shareWithClientIfNotShared ? 'primary--text' : ''"
                >
                  {{ currentCase.client.clientData.company_name }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="!sharedWithClient && currentCase.client && currentCase.client.clientType === 'individual'"
              v-model="shareWithClientIfNotShared"
              color="success"
              class="mt-n1"
            >
              <template v-slot:label>
                <div
                  :class="shareWithClientIfNotShared ? 'primary--text' : ''"
                >
                  {{ currentCase.client.clientData.given_names }}  {{ currentCase.client.clientData.surname }}
                </div>
              </template>
            </v-checkbox>
            <v-icon
              class="ml-2"
              color="primary"
              @click="infoCard = true"
            >
              mdi-information-variant
            </v-icon>
          </v-row>
          <v-row v-if="currentCase.client && !currentCase.client.realClient">
            <div
              class="ml-10 mr-8 mt-n1 mb-5 error--text"
              style="font-size: 15px; white-space: normal;"
            >
              {{ $t('expressions|no_real_client') }}
            </div>
          </v-row>
          <v-row v-if="!currentCase.client">
            <div
              class="mx-7 mt-n3 mb-5 error--text"
              style="font-size: 15px;"
            >
              {{ $t('clients|no_client') }}
            </div>
          </v-row>
          <v-card-subtitle class="mt-n3 black--text">
            {{ capitalizeFirstLetter($t('common|others')) }}:
          </v-card-subtitle>
          <div
            v-for="(section, index) in availableSections"
            :key="index"
            class="ml-5 mt-n5"
          >
            <v-checkbox
              v-model="checkboxSelection"
              multiple
              :value="section"
            >
              <template v-slot:label>
                <div
                  :class="checkboxSelection.includes(section) ? 'primary--text' : ''"
                >
                  {{ section }}
                </div>
              </template>
            </v-checkbox>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              rounded
              :disabled="(!checkboxSelection.length && sharedWithClient) || (!checkboxSelection.length && !shareWithClientIfNotShared)"
              right
              min-width="120"
              color="primary"
              @click="shareCase(currentCase)"
            >
              {{ $t('actions|submit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>
      <v-expand-transition>
        <v-card v-show="infoCard">
          <v-card-title class="text-h6 grey lighten-2">
            {{ $t('actions|share_case_with_client') }}
          </v-card-title>

          <v-card-text class="mt-4">
            {{ $t('expressions|info_share_case_with_client_1') }}
            <span class="font-weight-bold">{{ $t('expressions|info_share_case_with_client_2') }}</span>
            {{ $t('expressions|info_share_case_with_client_3') }}
            {{ $t('expressions|info_share_case_with_client_4') }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="infoCard = false"
            >
              {{ $t('actions|close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>
    </div>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    currentCase: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      dialog: false,
      shareWithClientIfNotShared: false,
      checkboxSelection: [],
      infoCard: false,
      hoverCloseButtonIcon: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    sharedWithClient () {
      if (this.currentCase.client && this.currentCase.client.realClient) {
        const isShared = this.currentCase.sharedWith.accounts.find(acc => acc._id === this.currentCase.client.clientAccount._id)
        if (isShared) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    availableSections () {
      let myAvailableSections = []
      if (this.account && this.account.companyId && this.company) {
        const isAlreadyShared = this.currentCase.sharedWith.company.find(c => c._id === this.company._id)
        if (!isAlreadyShared) {
          myAvailableSections.push(this.company.companyData.companyName)
        }
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          const participation = group.groupMembers.find(m => m._id === this.account._id)
          if (participation) {
            const isAlreadySharedWithGroup = this.currentCase.sharedWith.groups.find(g => g._id === group._id)
            if (!isAlreadySharedWithGroup) {
              const alreadyInArrayIndex = myAvailableSections.findIndex(item => item === group.groupName)
              if (alreadyInArrayIndex === -1) myAvailableSections.push(group.groupName)
            }
          }
        }
      }
      return myAvailableSections
    }
    // alreadySharedWith () {
    //   let returnArray = []
    //   this.currentCase.sharedWith.accounts.forEach(acc => {
    //     if (acc.accountType === 'individual' && acc._id === this.currentCase.client.clientAccount._id) {
    //       returnArray.push(acc.accountData.firstName + ' ' + acc.accountData.lastName)
    //     } else if (acc.accountType === 'company' && acc._id === this.currentCase.client.clientAccount._id) {
    //       returnArray.push(acc.accountData.company_name)
    //     } else {
    //       returnArray.push(acc.accountData.accountName)
    //     }
    //   })
    //   this.currentCase.sharedWith.company.forEach(c => {
    //     returnArray.push(c.companyData.companyName)
    //   })
    //   this.currentCase.sharedWith.groups.forEach(group => {
    //     returnArray.push(group.groupName)
    //   })
    //   return returnArray
    // }
  },
  methods: {
    ...mapActions({
      updateCase: 'cases/updateCase'
    }),
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    close () {
      this.dialog = false
      this.shareWithClientIfNotShared = false
      this.checkboxSelection = []
      this.infoCard = false
      this.hoverCloseButtonIcon = false
    },
    async shareCase (currentCase) {
      const _id = currentCase._id
      if (this.shareWithClientIfNotShared) {
        const payload = {
          payload: {
            shareCase: true,
            clientId: currentCase.client.clientAccount._id
          }
        }
        await this.updateCase({ _id, payload })
      }
      if (this.checkboxSelection.length) {
        let idsCompanyArr = []
        let idsGroupArr = []
        this.checkboxSelection.forEach(item => {
          if (this.company.companyData.companyName === item) {
            idsCompanyArr.push(this.company._id)
          }
          this.company.groups.forEach(group => {
            if (group.groupName === item) {
              idsGroupArr.push(group._id)
            }
          })
        })
        const payload = {
          payload: {
            shareCase: true,
            companyAndGroups: true,
            companies: idsCompanyArr,
            groups: idsGroupArr
          }
        }
        await this.updateCase({ _id, payload })
      }
      this.close()
    }
  }
}
</script>

<style scoped>
.vdialognew {
  padding: 0px;;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
