<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        {{ $t('cases|progress') }}
      </v-card-title>

      <v-card-text class="mt-4">
        {{ $t('expressions|progress_info') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          {{ $t('actions|close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data () {
    return {
      dialog: false
    }
  }
}
</script>
